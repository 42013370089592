import { Components } from '@mui/material/styles';
import variables from '../variables';

const MuiCardHeader: Components['MuiCardHeader'] = {
  styleOverrides: {
    root: () => ({
      borderBottom: `1px solid ${variables.colors.secondary200}`,
      paddingLeft: 32,
      paddingRight: 32,
      '& > .MuiCardHeader-action': {
        alignSelf: 'center',
        marginRight: -8,
      },
    }),
  },
};

export default MuiCardHeader;
