import {
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const LogoutButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 13,
}));

export default {};
