import { Components } from '@mui/material/styles';

const MuiCardContent: Components['MuiCardContent'] = {
  styleOverrides: {
    root: () => ({
      padding: 32,
      '&:last-child': {
        paddingBottom: 32,
      },
    }),
  },
};

export default MuiCardContent;
