import React from 'react';
import {
  Box,
  Divider,
  List,
  ListItemText,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { MenuListItem, MenuListItemButton } from './nav-menu.styles';
import { DashboardPage, DocumentsPage, Page } from '../../../routes/pages';

interface NavMenuProps extends SwipeableDrawerProps {
  onSelectOption: (page: Page) => unknown
  onLogout: () => unknown
}

function NavMenu({ onSelectOption, onLogout, ...props }: NavMenuProps) {
  const { t } = useTranslation();
  return (
    <SwipeableDrawer
      anchor="left"
      {...props}
    >
      <List>
        <MenuListItem>
          <MenuListItemButton
            onClick={() => onSelectOption(DashboardPage)}
          >
            <ListItemText primary={t('components.appBar.options.claims')} />
            <ArrowForwardIosIcon />
          </MenuListItemButton>
        </MenuListItem>
        <MenuListItem>
          <MenuListItemButton
            onClick={() => onSelectOption(DocumentsPage)}
          >
            <ListItemText primary={t('components.appBar.options.documents')} />
            <ArrowForwardIosIcon />
          </MenuListItemButton>
        </MenuListItem>
      </List>
      <Box>
        <Divider />
        <List>
          <MenuListItem>
            <MenuListItemButton
              onClick={() => onLogout()}
            >
              <ListItemText primary={t('components.appBar.options.signOut')} />
              <LogoutIcon />
            </MenuListItemButton>
          </MenuListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
}

export default NavMenu;
