export const cognito = {
  cognitoUserPoolId: 'eu-west-1_eM34XdRHh',
  cognitoIdentityPoolId: 'eu-west-1:4cf8e72c-50c2-4d02-9b9a-15afd2ebff75',
  cognitoAppClientId: 'vmtojt8qrk4hoqmd1ngft7qst',
  cognitoUserPoolDomain: 'theexeter-member-dev.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
};

export const googleAnalyticsTag = 'G-PD0M2WX1H9';
export const closedClaimsDisplayDate = '2024-12-01T00:00:00Z';

export const featureFlags = {
  staticClaimGuide: false,
};

export default {};
