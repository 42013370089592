import { Components } from '@mui/material/styles';
import variables from '../variables';
import typography from '../typography';

const MuiTableFooter: Components['MuiTableFooter'] = {
  styleOverrides: {
    root: () => ({
      '& td': {
        color: variables.colors.greyDark,
        backgroundColor: variables.colors.surface8,
        ...typography.h5,
        fontSize: typography.body2.fontSize,
      },
    }),
  },
};

export default MuiTableFooter;
