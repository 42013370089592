import { alpha } from '@mui/material';
import variables from '../variables';

const MuiDrawer = {
  styleOverrides: {
    root: () => ({
      '& .MuiModal-backdrop': {
        backgroundColor: alpha(variables.colors.surface, 0.8),
      },
    }),
  },
};

export default MuiDrawer;
