import React from 'react';
import {
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Container } from './mobile-upload.styles';
import CameraInput from './camera-input';
import FileInput from './file-input';

interface EvidenceUploadProps {
  onFileSelect: (files: File[]) => void;
  loading?: boolean;
}

function MobileUpload({
  onFileSelect,
  loading = false,
}: EvidenceUploadProps) {
  const { t } = useTranslation();

  return (
    <Container>
      {!loading ? (
        <Stack direction="row" alignItems="flex-start" justifyContent="space-evenly" sx={{ width: '100%' }}>
          <FileInput onFileSelect={onFileSelect} />
          <Divider orientation="vertical" flexItem />
          <CameraInput onFileSelect={(file) => onFileSelect([file])} />
        </Stack>
      ) : (
        <Stack direction="column" alignItems="center" gap={2}>
          <CircularProgress size={24} />
          <Typography variant="body1">
            {t('components.fileUpload.uploading')}
          </Typography>
        </Stack>
      )}
    </Container>
  );
}

export default MobileUpload;
