import {
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '../../types';

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderBottom: `3px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer - 1,
  '&:has(.info-bar)': {
    borderBottom: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    borderBottom: 'none',
    boxShadow: '0px 5px 10px -5px rgba(0,0,0,0.1)',
  },
}));

export const MainToolBar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minHeight: 94,
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 65,
  },
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'stretch',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  width: 170,
  [theme.breakpoints.down('sm')]: {
    width: 130,
  },
  '& > button:hover': {
    backgroundColor: 'transparent',
  },
}));

export const NavContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    borderRight: `1px solid ${theme.palette.common.surface5}`,
    alignSelf: 'stretch',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: 23,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: 0,
  },
})) as typeof Typography;

export const Nav = styled(Box)(() => ({
  position: 'relative',
  flex: 1,
}));

type NavButtonProps = {
  active?: boolean
};

export const NavButton = styled(Button, {
  shouldForwardProp: (prop) => shouldForwardProp<NavButtonProps>(['active'], prop),
})<NavButtonProps>(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : 'transparent',
  borderRadius: 0,
  color: theme.palette.common.textDark,
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: 13.5,
  height: 37,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  position: 'relative',
}));
