import React, { useEffect, useRef } from 'react';
import {
  Button,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Signature from 'react-signature-canvas';

interface SignatureCanvasProps {
  signature?: string | null;
  onEnd: (signature: string | null) => void;
}

function SignatureCanvas({ signature = null, onEnd }: SignatureCanvasProps) {
  const ref = useRef<Signature>(null);
  const { t } = useTranslation();

  const handleClear = () => {
    ref.current?.clear();
    onEnd(null);
  };

  const handleEnd = () => {
    if (ref.current) {
      onEnd(ref.current?.getTrimmedCanvas().toDataURL('image/png'));
    }
  };

  useEffect(() => {
    if (signature && ref.current) {
      ref.current.fromDataURL(signature);
    }
  }, [signature]);

  return (
    <Stack direction="column">
      <Stack direction="column" sx={(theme) => ({ backgroundColor: theme.palette.common.background4 })}>
        <Signature
          penColor="black"
          ref={ref}
          onEnd={handleEnd}
          canvasProps={{ height: 300 }}
        />
      </Stack>
      <Button
        variant="text"
        color="info"
        sx={{
          justifySelf: 'flex-end',
          alignSelf: 'flex-end',
          textDecoration: 'underline',
          color: 'common.blue',
        }}
        onClick={handleClear}
      >
        {t('components.signatureCanvas.clear')}
      </Button>
    </Stack>
  );
}

export default SignatureCanvas;
