import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { WelcomeContainer } from './welcome-message.styles';

function WelcomeMessage() {
  const { t } = useTranslation();
  return (
    <WelcomeContainer>
      <Typography variant="h1">{t('components.documents.hero.title')}</Typography>
    </WelcomeContainer>
  );
}

export default WelcomeMessage;
