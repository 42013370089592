import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  ModalCardActions,
  ModalCardContent,
  ModalCardHeader,
  ModalCloseButton,
} from './signature-modal.styles';
import SignatureCanvas from './signature-canvas';
import SignatureUpload from '../signature-upload';
import SignatureTextInput from './signature-text-input';
import useBreakpoint from '../../hooks/use-breakpoints';

export enum SignatureType {
  DRAW = 'draw',
  UPLOAD = 'upload',
  TYPE = 'type',
}

export type SignatureData = {
  name: string,
  signature: string | null,
  date?: string | null,
};

export const defaultSignatureData = {
  name: '',
  signature: null,
  date: null,
};

export interface SignatureFormProps {
  acceptedTypes?: SignatureType[],
  onClose: () => void;
  onConfirm: (signatureData: SignatureData) => void;
}

function SignatureForm({
  onClose,
  onConfirm,
  acceptedTypes = [SignatureType.DRAW, SignatureType.UPLOAD],
}: SignatureFormProps) {
  const { t } = useTranslation();
  const isWide = useBreakpoint('sm');
  const [signatureType, setSignatureType] = useState<SignatureType>(SignatureType.DRAW);
  const [signatureData, setSignatureData] = useState<SignatureData>(defaultSignatureData);

  const handleFileSelect = (files: File[]) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setSignatureData((state) => ({ ...state, signature: reader.result as string }));
      }
    };
  };

  const handleChangeType = (value: SignatureType) => {
    setSignatureType(value);
    setSignatureData((state) => ({ ...state, signature: null }));
  };

  const handleConfirm = () => {
    if (signatureData.signature && signatureData.name.length > 0) {
      onConfirm(signatureData);
    }
  };

  return (
    <>
      <ModalCardHeader
        title={t('components.signatureModal.title')}
        subheader={t('components.signatureModal.subHeader')}
        titleTypographyProps={{ variant: 'h3' }}
      />
      <ModalCardContent>
        <Stack direction="column">
          <FormControl>
            <FormLabel htmlFor="name">{t('components.signatureModal.fullName')}</FormLabel>
            <TextField
              sx={{ mt: 0.5, mb: 1 }}
              id="name"
              name="name"
              value={signatureData.name}
              onChange={(event) => setSignatureData((state) => ({ ...state, name: event.target.value }))}
            />
          </FormControl>
          <FormControl>
            <RadioGroup
              row
              name="signatureType"
              value={signatureType}
              onChange={(event, value) => handleChangeType(value as SignatureType)}
            >
              {acceptedTypes.map((type) => (
                <FormControlLabel key={type} value={type} control={<Radio />} label={t(`components.signatureModal.${type}`)} />
              ))}
            </RadioGroup>
          </FormControl>
          <Box sx={{ minHeight: 200 }}>
            {signatureType === SignatureType.DRAW && (
              <SignatureCanvas onEnd={(signature) => setSignatureData((state) => ({ ...state, signature }))} />
            )}
            {signatureType === SignatureType.UPLOAD && (
              <>
                <SignatureUpload onFileSelect={handleFileSelect} />
                {signatureData.signature && <Box sx={{ mt: 2 }}><img src={signatureData.signature} alt="" height="50" /></Box>}
              </>
            )}
            {signatureType === SignatureType.TYPE && (
              <SignatureTextInput onEnd={(signature) => setSignatureData((state) => ({ ...state, signature }))} />
            )}
          </Box>
        </Stack>
      </ModalCardContent>
      <ModalCardActions>
        <Stack direction="column" gap={2} alignItems="flex-start">
          <Typography variant="body1" gutterBottom color="common.textGrey">
            {t('components.signatureModal.agreement')}
          </Typography>
          <Stack
            direction={isWide ? 'row' : 'column-reverse'}
            sx={{ width: '100%' }}
            gap={2}
            alignItems={isWide ? 'flex-end' : 'none'}
            alignSelf="flex-end"
            justifyContent="end"
          >
            <Button
              variant="text"
              color="secondary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!signatureData.signature || signatureData.name.length <= 0}
              onClick={handleConfirm}
            >
              {t('components.signatureModal.save')}
            </Button>
          </Stack>
        </Stack>
      </ModalCardActions>
      <ModalCloseButton aria-label={t('common.close')!} onClick={onClose}>
        <CloseIcon />
      </ModalCloseButton>
    </>
  );
}

export default SignatureForm;
