const APP_REDIRECT_URI = 'APP_REDIRECT_URI';

const authRedirectHelper = () => {
  const getRedirectUri = () => sessionStorage.getItem(APP_REDIRECT_URI);
  const removeRedirectUri = () => sessionStorage.removeItem(APP_REDIRECT_URI);

  const setRedirectUri = (uri: string) => {
    if (!getRedirectUri()) {
      sessionStorage.setItem(APP_REDIRECT_URI, uri);
    }
  };

  const redirectToUri = () => {
    const uri = getRedirectUri();
    if (uri) {
      window.history.replaceState({}, '', uri);
      removeRedirectUri();
    }
  };

  return { setRedirectUri, redirectToUri };
};

export default authRedirectHelper;
