const MuiAutocomplete = {
  styleOverrides: {
    root: () => ({
      '& .MuiOutlinedInput-root': {
        paddingTop: 2,
        paddingBottom: 2,
      },
    }),
  },
};

export default MuiAutocomplete;
