import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectMemberStateMember } from '../../features/MemberSlice';
import Loader from '../../components/loader';
import DocumentsLayout from '../../components/documents-layout';
import DocumentsListContainer from '../../components/documents-list-container';
import DocumentCard from '../../components/document-card';

function Documents() {
  const { documents } = useAppSelector(selectMemberStateMember);

  if (!documents) {
    return <Loader />;
  }

  return (
    <DocumentsLayout>
      <DocumentsListContainer>
        {documents.map((document) => <DocumentCard document={document} key={document.documentId} />)}
      </DocumentsListContainer>
    </DocumentsLayout>
  );
}

export default Documents;
