import { Box } from '@mui/material';
import MuiContainer from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const Container = styled(MuiContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const OutletContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  minHeight: '100vh',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
