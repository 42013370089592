import { EvidenceStatus } from './evidence-helpers';
import http from './http';
import { EvidenceDto } from './models/evidence-dto';
import { EvidenceWithoutServerGeneratedFields } from './models/evidence-without-server-generated-fields';
import { OptionDto } from './models/option-dto';

async function getOptionListCategories(branch: string, tag: string, optionList: string): Promise<string[]> {
  return http.get(`/api/v1/bff/${branch}/${tag}/${optionList}/categories`);
}

async function getOptionListCategoryOptions(branch: string, tag: string, optionList: string, category: string): Promise<OptionDto[]> {
  return http.get(`/api/v1/bff/${branch}/${tag}/${optionList}/${category}/options`);
}

async function createUnsolicitedEvidence(tag: string, data: EvidenceWithoutServerGeneratedFields): Promise<EvidenceDto> {
  return http.post(`/api/v1/bff/evidences?TagName=${tag}&addTask=true`, data);
}

async function updateEvidenceStatus(evidenceId: string, status: EvidenceStatus): Promise<EvidenceDto> {
  return http.put(`/api/v1/bff/evidences/${evidenceId}/status`, status, { headers: { 'Content-Type': 'application/json-patch+json' } });
}

const evidenceApi = {
  getOptionListCategories,
  getOptionListCategoryOptions,
  createUnsolicitedEvidence,
  updateEvidenceStatus,
};

export default evidenceApi;
