import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ClaimPanel = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.background4,
  display: 'flex',
  minHeight: 120,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
  position: 'relative',
  '& .MuiTypography-body2': {
    color: '#61728C',
  },
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
    },
    '& > div': {
      '&:first-of-type': {
        [theme.breakpoints.up('md')]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.down('md')]: {
          borderBottom: `1px solid ${theme.palette.common.secondary200}`,
          flexDirection: 'column-reverse',
          paddingBottom: theme.spacing(1.5),
          marginBottom: theme.spacing(2),
        },
      },
      '&:first-of-type, &:last-of-type': {
        [theme.breakpoints.up('md')]: {
          borderRight: `1px solid ${theme.palette.common.secondary200}`,
        },
      },
      '& .MuiTypography-h2': {
        fontFamily: theme.typography.body1.fontFamily,
      },
      '& .MuiTypography-body1': {
        fontFamily: theme.typography.h6.fontFamily,
      },
    },
    '& > button': {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(4),
        minWidth: 164,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: theme.spacing(1),
      },
    },
  },
}));

export default {};
