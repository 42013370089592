import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  position: 'fixed',
  top: 0,
  zIndex: 1200,
  height: 5,
  width: '100%',
}));

export default {};
