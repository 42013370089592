import React, { PropsWithChildren, useState } from 'react';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AnyObjectSchema } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormFooter, FormHeader } from './evidence-form.styles';
import CreateSignature from './create-signature';
import { defaultSignatureData, SignatureData } from '../signature-model/signature-form';
import useBusyState from '../use-busy-state';
import { GenerateEvidenceDocumentWithSignatureDto } from '../../services/models/generate-evidence-document-with-signature-dto';

interface EvidenceFormViewProps extends PropsWithChildren {
  title: string;
  schema: AnyObjectSchema;
  onDownload?: () => void;
  onSave: (values: GenerateEvidenceDocumentWithSignatureDto) => void;
}

function EvidenceFormView({
  title,
  schema,
  onDownload = undefined,
  onSave,
  children,
}: EvidenceFormViewProps) {
  const { t } = useTranslation();
  const [signatureData, setSignatureData] = useState<SignatureData>(defaultSignatureData);
  const [busy, withBusyState] = useBusyState();
  const [downloading, withDownloadingState] = useBusyState();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const {
    formState: { isValid },
    setValue,
    handleSubmit,
  } = formMethods;

  const handleSignatureChange = (data: SignatureData) => {
    setSignatureData(data);
    setValue('name', data.name, { shouldDirty: true, shouldValidate: true });
    setValue('consentDate', data.date, { shouldDirty: true, shouldValidate: true });
    setValue('signature', data.signature, { shouldDirty: true, shouldValidate: true });
  };

  const handleDownload = withDownloadingState(async () => {
    if (onDownload) {
      await onDownload();
    }
  });

  const handleSave = withBusyState(async (values) => {
    await onSave(values);
  });

  return (
    <Container>
      <FormProvider {...formMethods}>
        <FormHeader direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h1"
          >
            {title}
          </Typography>
          {onDownload && (
            <LoadingButton
              variant="outlined"
              color="secondary"
              onClick={handleDownload}
              loading={downloading}
            >
              {t('common.downloadForm')}
            </LoadingButton>
          )}
        </FormHeader>
        <Box>
          {children}
        </Box>
        <CreateSignature
          sx={(theme) => ({ pt: 4, mt: 4, borderTop: `1px solid ${theme.palette.common.background4}` })}
          signatureData={signatureData}
          onChange={handleSignatureChange}
          onReset={() => setSignatureData(defaultSignatureData)}
        />
        <FormFooter alignItems="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!isValid}
            type="submit"
            loading={busy}
            onClick={handleSubmit(handleSave)}
          >
            {t('common.saveConfirm')}
          </LoadingButton>
        </FormFooter>
      </FormProvider>
    </Container>
  );
}

export default EvidenceFormView;
