import typography from '../typography';

const MuiTableBody = {
  styleOverrides: {
    root: () => ({
      '& th': {
        ...typography.h5,
        fontSize: typography.body2.fontSize,
      },
    }),
  },
};

export default MuiTableBody;
