export const getFileExtensionFromContentDisposition = (contentDisposition = '') => {
  const parts = contentDisposition.split(';');
  const filenamePart = parts.find((part) => part.trim().startsWith('filename='));

  if (filenamePart) {
    const filename = filenamePart.split('=')[1].trim().replace(/['"]/g, '');

    if (filename.split('.').length > 1) {
      return `.${filename.split('.').pop()}`;
    }
  }

  return '';
};

export default {};
