/* eslint-disable react/no-danger */
import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WelcomeContainer } from './welcome-message.styles';
import { Container } from '../../../claims/claims.styles';
import { MyClaim } from '../../../../services/models/my-claim';
import { ClaimIncapacityType } from '../../../../services/claims-helpers';

interface WelcomeMessageProps {
  claim: MyClaim
}

function WelcomeMessage({ claim }: WelcomeMessageProps) {
  const { t } = useTranslation();
  const incapacity = claim.incapacity?.toLowerCase();
  const isValidIncapacity = incapacity === ClaimIncapacityType.Illness || incapacity === ClaimIncapacityType.Injury;

  return (
    <WelcomeContainer>
      <Container sx={{ height: '100%', justifyContent: 'center' }}>
        <Stack>
          <Typography variant="h1" component="h3" gutterBottom>
            {isValidIncapacity ? t(`components.claimDetail.incapacity.${incapacity}`) : t('components.claimDetail.incapacity.notApplicable')}
          </Typography>
          <Typography variant="body1">
            {t('components.claimDetail.welcomeIntro')}
          </Typography>
          <Typography variant="body1">
            <span dangerouslySetInnerHTML={{ __html: t('components.claimDetail.welcomeContact')! }} />
          </Typography>
        </Stack>
      </Container>
    </WelcomeContainer>
  );
}

export default WelcomeMessage;
