import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SignatureModal from '../signature-model';
import { asClientDate, serverDateFormat } from '../../utils/converters';
import { SignatureData, SignatureType } from '../signature-model/signature-form';
import useBreakpoint from '../../hooks/use-breakpoints';
import SignatureDrawer from '../signature-model/signature-drawer';

interface CreateSignatureProps extends Omit<BoxProps, 'onChange'> {
  onChange: (signatureData: SignatureData) => void;
  onReset: () => void;
  signatureData: SignatureData;
}

function CreateSignature({
  onChange,
  onReset,
  signatureData,
  ...props
}: CreateSignatureProps) {
  const { t } = useTranslation();
  const isWide = useBreakpoint('sm');
  const [open, setOpen] = useState<boolean>(false);

  const handleConfirm = (data: SignatureData) => {
    setOpen(false);
    const updatedSignatureData = { ...data, date: moment().format(serverDateFormat) };
    onChange(updatedSignatureData);
  };

  const handleChange = () => {
    onReset();
    setOpen(true);
  };

  const signatureProps = {
    open,
    onClose: () => setOpen(false),
    onConfirm: handleConfirm,
    acceptedTypes: [SignatureType.DRAW, SignatureType.UPLOAD, SignatureType.TYPE],
  };

  return (
    <Box {...props}>
      <Stack direction="row" gap={2}>
        <Stack alignItems="flex-start">
          <Typography variant="body1">
            <strong>{t('components.createSignature.title')}</strong>
          </Typography>
          {!signatureData.signature ? (
            <>
              <Typography variant="body2" gutterBottom>
                {t('components.createSignature.description')}
              </Typography>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                {t('components.createSignature.sign')}
              </Button>
              {!isWide ? <SignatureDrawer {...signatureProps} /> : <SignatureModal {...signatureProps} />}
            </>
          ) : (
            <>
              <Stack direction="row" alignItems="flex-start" gap={2}>
                <Box
                  sx={{
                    minWidth: 400,
                    minHeight: 150,
                    maxWidth: 400,
                    maxHeight: 150,
                    backgroundImage: `url(${signatureData.signature})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'center',
                  }}
                />
              </Stack>
              <Button
                variant="text"
                color="info"
                sx={{
                  textDecoration: 'underline',
                  color: 'common.blue',
                }}
                onClick={handleChange}
              >
                {t('components.createSignature.change')}
              </Button>
            </>
          )}
        </Stack>
        {signatureData.signature && (
          <Typography>
            <strong>{t('components.createSignature.date')}</strong>
            <br />
            {asClientDate(signatureData.date)}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default CreateSignature;
