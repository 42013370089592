import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface FileListProps {
  files: File[];
  onDelete?: (file: File) => unknown;
}

function FileList({ files, onDelete = undefined }: FileListProps) {
  if (files.length <= 0) {
    return null;
  }
  return (
    <List dense>
      {files.map((file) => (
        <ListItem
          key={file.name}
          secondaryAction={onDelete && (
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(file)}>
              <DeleteIcon />
            </IconButton>
          )}
        >
          <ListItemText
            primary={file.name}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default FileList;
