import React, { PropsWithChildren } from 'react';
import { Button, ButtonProps } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container } from './back-bar.styles';

interface BackBarProps extends PropsWithChildren {
  backButtonProps: ButtonProps
  hideButton?: boolean
}

function BackBar({ backButtonProps, hideButton = false, children }: BackBarProps) {
  return (
    <Container>
      {!hideButton && (
        <Button
          startIcon={<ArrowBackIcon />}
          variant="text"
          color="secondary"
          size="small"
          {...backButtonProps}
        />
      )}
      {children}
      <span />
    </Container>
  );
}

export default BackBar;
