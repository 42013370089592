import variables from '../variables';

const MuiTableCell = {
  styleOverrides: {
    root: () => ({
      paddingTop: 10,
      paddingBottom: 10,
      borderBottom: `1px solid ${variables.colors.surface5}`,
    }),
  },
};

export default MuiTableCell;
