import { alpha } from '@mui/material';
import variables from '../variables';

const MuiButton = {
  styleOverrides: {
    root: ({ ownerState }: any) => ({
      boxShadow: 'none',
      fontFamily: '"FsMeBold"',
      textTransform: 'none' as const,
      '&:hover': {
        boxShadow: 'none',
      },
      ...(ownerState.size === 'small' && {
        fontSize: 14,
      }),
      ...(ownerState.size === 'medium' && {
        fontSize: 16,
      }),
      ...(ownerState.size === 'large' && {
        fontSize: 16,
        paddingTop: 6,
        paddingBottom: 6,
        lineHeight: 2,
        '&:disabled': {
          paddingTop: 5,
          paddingBottom: 5,
        },
      }),
      ...(ownerState.variant === 'contained'
      && ownerState.color === 'primary' && {
        '&:hover': {
          backgroundColor: variables.colors.primary800,
          boxShadow: 'none',
          color: variables.colors.greyDark,
        },
        '&:disabled': {
          backgroundColor: variables.colors.primary200,
          color: alpha(variables.colors.greyDark, 0.2),
        },
      }),
      ...(ownerState.variant === 'contained'
      && ownerState.color === 'secondary' && {
        '&:hover': {
          backgroundColor: variables.colors.secondary800,
          boxShadow: 'none',
          color: variables.colors.white,
        },
        '&:disabled': {
          backgroundColor: variables.colors.secondary200,
          color: variables.colors.white,
        },
      }),
      ...(ownerState.variant === 'outlined'
      && ownerState.color === 'primary' && {
        backgroundColor: variables.colors.white,
        borderColor: variables.colors.primary,
        borderWidth: 2,
        color: variables.colors.secondary,
        '& svg': {
          transition: 'color 250ms',
        },
        '&:hover': {
          backgroundColor: variables.colors.white,
          borderColor: variables.colors.primary600,
          borderWidth: 2,
          boxShadow: 'none',
          color: variables.colors.secondary600,
          '& svg': {
            color: variables.colors.secondary600,
          },
        },
      }),
      ...(ownerState.variant === 'outlined'
      && ownerState.color === 'secondary' && {
        backgroundColor: variables.colors.white,
        borderColor: variables.colors.secondary,
        borderWidth: 2,
        '& svg': {
          transition: 'color 250ms',
        },
        '&:hover': {
          backgroundColor: variables.colors.white,
          borderColor: variables.colors.secondary600,
          borderWidth: 2,
          boxShadow: 'none',
          color: variables.colors.secondary600,
          '& svg': {
            color: variables.colors.secondary600,
          },
        },
      }),
      ...(ownerState.variant === 'outlined'
      && {
        '&:disabled': {
          backgroundColor: variables.colors.white,
          borderColor: variables.colors.secondary200,
          borderWidth: 2,
          color: variables.colors.secondary200,
        },
      }),
    }),
  },
};

export default MuiButton;
