import { Components } from '@mui/material/styles';

const MuiCardActions: Components['MuiCardActions'] = {
  styleOverrides: {
    root: () => ({
      paddingLeft: 32,
      paddingRight: 32,
      minHeight: 80,
    }),
  },
};

export default MuiCardActions;
