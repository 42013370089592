import { useEffect, useState } from 'react';

const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const mobile = /android|iphone|ipod|ipad|mobile|windows phone/i.test(userAgent);
    setIsMobile(mobile);
  }, []);

  return isMobile;
};

export default useMobileDetect;
