import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export const InfoCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[24],
}));

export const InfoCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface,
  minHeight: 64,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  '& > div > h3': {
    color: theme.palette.common.white,
    fontFamily: theme.typography.h3.fontFamily,
    marginTop: 0,
  },
})) as typeof CardHeader;

export const InfoCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:last-child': {
    paddingBottom: theme.spacing(3),
  },
}));
