import React, { MouseEvent, useState } from 'react';
import {
  Box,
  BoxProps,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { LogoutButton } from './user-menu.styles';

interface UserMenuProps extends BoxProps {
  username: string;
  onLogout: () => unknown
}

function UserMenu({ username, onLogout, ...props }: UserMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box {...props}>
      <LogoutButton
        size="large"
        aria-label="user options"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="secondary"
        startIcon={<AccountCircleOutlinedIcon />}
      >
        {username}
      </LogoutButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onLogout}>
          <ListItemText>{t('common.signOut')}</ListItemText>
          <LogoutIcon fontSize="small" sx={{ ml: 2 }} />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default UserMenu;
