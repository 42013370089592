export const shouldForwardProp = <CustomProps extends Record<string, unknown>>(
  props: Array<keyof CustomProps>,
  prop: PropertyKey,
): boolean => !props.includes(prop as string);

export default shouldForwardProp;

export interface WithOnChangeCommitted<T> {
  onChangeCommitted?: (name: string, value: T) => unknown;
}

export interface Option {
  value: string;
  label?: string;
}

export function getLabelAndValue(option: string | Option, t: (t: string) => string, labelTranslationBasePath?: string): Required<Option> {
  let label;
  let value;
  if (typeof option === 'string') {
    label = option;
    value = option;
  } else {
    label = option.label || option.value;
    value = option.value;
  }
  if (labelTranslationBasePath !== undefined) {
    label = t(`${labelTranslationBasePath}.${label}`);
  }
  return { label, value };
}
