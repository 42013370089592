import variables from '../variables';

const MuiTableRow = {
  styleOverrides: {
    root: () => ({
      '&:nth-of-type(even)': {
        backgroundColor: variables.colors.surface8,
      },
    }),
  },
};

export default MuiTableRow;
