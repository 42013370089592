import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Container } from './claims.styles';
import { useAppSelector } from '../../store/hooks';
import { selectMemberStateMember } from '../../features/MemberSlice';
import ClaimsLayout from './components/claims-layout';
import { getActiveClaims, getPreviousClaims, isAfterClaimDisplayDate } from '../../services/claims-helpers';
import ClaimsList from './components/claims-list';
import ClaimPanel from './components/claim-panel';
import { ClaimDetailsPage, DocumentsPage, Page } from '../pages';
import Loader from '../../components/loader';
import { MyClaim } from '../../services/models/my-claim';
import claimsApi from '../../services/claims-api';
import useBusyState from '../../hooks/use-busy-state';

function Claims() {
  const { t } = useTranslation();
  const initialised = useRef<boolean>(false);
  const { member } = useAppSelector(selectMemberStateMember);
  const [activeClaims, setActiveClaims] = useState<MyClaim[]>();
  const [previousClaims, setPreviousClaims] = useState<MyClaim[]>();
  const [busy, withBusyState] = useBusyState();
  const navigate = useNavigate();

  const handleNavigate = (page: Page, claim: MyClaim, state: any = {}) => {
    navigate(generatePath(page.path, { claimId: claim.claimId || null, evidenceId: null }), { state });
  };

  const init = useCallback(withBusyState(async () => {
    try {
      const response = await claimsApi.getMemberClaims();
      setActiveClaims(getActiveClaims(response));
      setPreviousClaims(isAfterClaimDisplayDate() ? getPreviousClaims(response) : []);
    } catch {
      toast('Error uploading document', {
        position: 'bottom-left',
        type: 'error',
      });
    }
  }), [setActiveClaims]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      init();
    }
  }, [member]);

  useEffect(() => {
    if (activeClaims && activeClaims.length === 1) {
      const claim = activeClaims.at(0);
      if (claim) {
        handleNavigate(ClaimDetailsPage, claim, { memberId: member.id, isSingleClaim: true });
      }
    } else if (activeClaims?.length === 0 && previousClaims?.length === 0) {
      navigate(generatePath(DocumentsPage.path));
    }
  }, [activeClaims, previousClaims]);

  if (busy || !member || !activeClaims) {
    return <Loader />;
  }

  if (activeClaims.length === 1) {
    return null;
  }

  return (
    <ClaimsLayout>
      <Container disableGutters maxWidth={false}>
        <Container sx={{ pt: 4, pb: 4 }}>
          <Typography variant="h1">{t('components.claims.title')}</Typography>
          <Typography component="p" variant="h3">{t('components.claims.subTitle')}</Typography>
          <Divider />
          <Stack direction="column" gap={4}>
            {activeClaims.length > 0 && (
              <ClaimsList type="Active">
                {activeClaims.map((claim) => (
                  <ClaimPanel
                    key={claim.claimId}
                    claim={claim}
                    type="Active"
                    onView={(selectedClaim) => handleNavigate(ClaimDetailsPage, selectedClaim, { memberId: member.id })}
                  />
                ))}
              </ClaimsList>
            )}
            {previousClaims && previousClaims.length > 0 && (
              <ClaimsList type="Previous">
                {previousClaims.map((claim) => (
                  <ClaimPanel
                    key={claim.claimId}
                    claim={claim}
                    type="Previous"
                    onView={(selectedClaim) => handleNavigate(ClaimDetailsPage, selectedClaim, { memberId: member.id })}
                  />
                ))}
              </ClaimsList>
            )}
          </Stack>
        </Container>
      </Container>
    </ClaimsLayout>
  );
}

export default Claims;
