import React, { PropsWithChildren } from 'react';
import { MainContent } from './claims-layout.styles';

interface ClaimsLayoutProps extends PropsWithChildren {}

function ClaimsLayout({
  children,
}: ClaimsLayoutProps) {
  return (
    <MainContent maxWidth={false} disableGutters>
      {children}
    </MainContent>
  );
}

export default ClaimsLayout;
