import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import authRedirectHelper from './app-auth.utils';
import AppRoutes from '../../routes/routes';

export function AppAuth() {
  const { route } = useAuthenticator((context: { route: any; }) => [context.route]);
  const { setRedirectUri, redirectToUri } = authRedirectHelper();

  useEffect(() => {
    const federatedSignIn = async () => {
      if (process.env.REACT_APP_AZURE_DISABLED !== 'true') {
        await Auth.federatedSignIn({ customProvider: 'AzureAD' });
      }
    };
    if (route !== 'authenticated' && route !== 'idle') {
      const currentUri = window.location.pathname + window.location.search;
      setRedirectUri(currentUri);

      federatedSignIn();
    }
  }, [route]);

  if (route === 'authenticated') {
    redirectToUri();
    return <AppRoutes />;
  }

  if (process.env.REACT_APP_AZURE_DISABLED === 'true') {
    return (
      <Box sx={{
        height: '100vh',
        padding: 4,
        backgroundColor: '#f6f6f6',
      }}
      >
        <View className="auth-wrapper">
          <Authenticator hideSignUp />
        </View>
      </Box>
    );
  }

  return <span />;
}

export default AppAuth;
