import React from 'react';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Authenticator } from '@aws-amplify/ui-react';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import { store } from './store';
import { cognito } from './exports';
import { AppAuth } from './components/app-auth';

const awsconfig = {
  aws_cognito_region: cognito.cognitoRegion,
  aws_user_pools_id: cognito.cognitoUserPoolId,
  aws_user_pools_web_client_id: cognito.cognitoAppClientId,
  aws_cognito_identity_pool_id: cognito.cognitoIdentityPoolId,
  aws_cognito_user_pool_domain: cognito.cognitoUserPoolDomain,
  oauth: {
    domain: cognito.cognitoUserPoolDomain,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin', 'memberportal/allow_access'],
    redirectSignIn: `${window.location.origin}`,
    redirectSignOut: `${window.location.origin}`,
    responseType: 'code',
  },
};

Amplify.configure(awsconfig);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <Authenticator.Provider>
            <AppAuth />
          </Authenticator.Provider>
        </LocalizationProvider>
      </Provider>
      <ToastContainer newestOnTop />
    </ThemeProvider>
  );
}

export default App;
