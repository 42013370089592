import { Container as MuiContainer, styled, Typography } from '@mui/material';

export const Container = styled(MuiContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
}));

export const Header = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export default {};
