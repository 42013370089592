/* tslint:disable */
/* eslint-disable */
/**
 * WorkbenchService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @enum {string}
 */

export const EvidenceFulfillment = {
    MemberSupplyOnly: 'MemberSupplyOnly',
    ThirdParty: 'ThirdParty',
    Member: 'Member',
    ThirdPartyViaMember: 'ThirdPartyViaMember',
    ApprovalOnly: 'ApprovalOnly',
    Internal: 'Internal',
} as const;

export type EvidenceFulfillment = typeof EvidenceFulfillment[keyof typeof EvidenceFulfillment];



