import { alpha } from '@mui/material';
import typography from '../typography';
import variables from '../variables';

const MuiChip = {
  styleOverrides: {
    root: () => ({
      ...typography.caption,
      height: 'auto',
      minHeight: 25,
      '& .MuiChip-label': {
        paddingLeft: 16,
        paddingRight: 16,
        whiteSpace: 'break-spaces',
      },
      '&.MuiChip-colorSuccess': {
        backgroundColor: variables.colors.background3,
        color: variables.colors.green,
        fontFamily: typography.h5.fontFamily,
      },
      '&.MuiChip-colorError': {
        backgroundColor: alpha(variables.colors.red, 0.3),
        color: variables.colors.red,
        fontFamily: typography.h5.fontFamily,
      },
      '&.MuiChip-colorWarning': {
        backgroundColor: alpha(variables.colors.yellow, 0.2),
        color: variables.colors.yellow,
        fontFamily: typography.h5.fontFamily,
      },
    }),
  },
};

export default MuiChip;
