import React from 'react';
import { LinearProgressProps } from '@mui/material';
import { StyledLinearProgress } from './loader.styles';

interface LoaderProps extends LinearProgressProps {}

function Loader({ ...props }: LoaderProps) {
  return (
    <StyledLinearProgress {...props} />
  );
}

export default Loader;
