import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClaimEvidenceFulfilmentGroup } from '../../../../services/models/claim-evidence-fulfilment-group';

interface EvidenceGroupProps {
  group: ClaimEvidenceFulfilmentGroup
}

function EvidenceGroup({ group, children = null }: PropsWithChildren<EvidenceGroupProps>) {
  const { t } = useTranslation();
  const { text, completed, total } = group;

  if (total === 0) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h3" sx={{ mb: 2 }}>
        {text}
        <Typography component="span" fontSize="1.25rem" lineHeight={1.25} sx={(theme) => ({ ml: 1, color: theme.palette.common.secondary })}>
          {t('components.claimDetail.todoListProgress', { completed, total })}
        </Typography>
      </Typography>
      {children}
    </Box>
  );
}

export default EvidenceGroup;
