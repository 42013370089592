import variables from '../variables';
import typography from '../typography';

const MuiStepIcon = {
  styleOverrides: {
    root: () => ({
      width: 30,
      height: 30,
      border: `1px solid ${variables.colors.secondary}`,
      borderRadius: '50%',
      color: variables.colors.white,
      '& .MuiStepIcon-text': {
        ...typography.h4,
        fontSize: 15,
        fill: variables.colors.secondary,
      },
      '&.Mui-active': {
        border: 'none',
        color: variables.colors.yellow,
      },
    }),
  },
};

export default MuiStepIcon;
