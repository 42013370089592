import React, { PropsWithChildren } from 'react';
import {
  Stack,
} from '@mui/material';
import { Container } from './documents-layout.styles';
import WelcomeMessage from '../documents-welcome-message';

function DocumentsLayout({ children }: PropsWithChildren) {
  return (
    <Container disableGutters maxWidth={false}>
      <WelcomeMessage />
      <Container sx={{ pt: 4, pb: 4 }}>
        <Stack direction="column" gap={4}>
          {children}
        </Stack>
      </Container>
    </Container>
  );
}

export default DocumentsLayout;
