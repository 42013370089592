import http from './http';
import { MemberDto } from './models/member-dto';

async function getMember(): Promise<MemberDto> {
  return http.get('/api/v1/members');
}

const memberApi = {
  getMember,
};

export default memberApi;
