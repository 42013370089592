import React, { useCallback, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Modal,
  ModalProps,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import {
  ModalCard,
  ModalCardActions,
  ModalCardContent,
  ModalCardHeader,
  ModalCloseButton,
} from './unsolicited-evidence-modal.styles';
import useBreakpoint from '../../../../hooks/use-breakpoints';
import FileUpload from '../../../../components/file-upload';
import FileList from '../evidence-upload/file-list';
import useBusyState from '../../../../hooks/use-busy-state';
import useOptionList from '../../../../hooks/use-optionlist';
import { MyClaim } from '../../../../services/models/my-claim';
import { Select } from '../../../../components/select';
import { EvidenceStatus } from '../../../../services/models/evidence-status';
import { EvidenceFulfillment } from '../../../../services/models/evidence-fulfillment';
import useUploadEvidence from '../../../../hooks/use-upload-evidence';
import { MemberDto } from '../../../../services/models/member-dto';
import evidenceApi from '../../../../services/evidence-api';
import { useAppDispatch } from '../../../../store/hooks';
import { updateEvidences } from '../../../../features/ClaimDetailSlice';
import MobileUpload from '../../../../components/mobile-upload';
import useMobileDetect from '../../../../hooks/use-mobile-detect';

interface UnsolicitedEvidenceModalProps extends Omit<ModalProps, 'onClose' | 'children'> {
  claim: MyClaim;
  member: MemberDto;
  onClose: () => void;
  onConfirm: () => void;
}

function UnsolicitedEvidenceModal({
  claim,
  member,
  onClose,
  onConfirm,
  ...props
}: UnsolicitedEvidenceModalProps) {
  const { t } = useTranslation();
  const [uploadDocument, { uploading }] = useUploadEvidence();
  const isWide = useBreakpoint('sm');
  const [files, setFiles] = useState<File[]>([]);
  const [busy, withBusyState] = useBusyState();
  const [categories, options, getCategoryOptions] = useOptionList(claim.branch, claim.tag, 'EvidenceCodesOptions');
  const [category, setCategory] = useState('');
  const [option, setOption] = useState('');
  const dispatch = useAppDispatch();
  const isMobile = useMobileDetect();

  const handleSelectCategory = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    setCategory(value as string);
    getCategoryOptions(value as string);
  };

  const handleSelectOption = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    setOption(value as string);
  };

  const handleFileSelect = async (newFiles: File[]) => {
    const file = newFiles[0];
    setFiles((state) => [...state, file]);
  };

  const handleSubmit = useCallback(withBusyState(async () => {
    const selectedOption = options?.find((opt) => opt.name === option);
    if (selectedOption) {
      try {
        const evidence = await evidenceApi.createUnsolicitedEvidence(claim.tag, {
          evidenceName: selectedOption.text,
          evidenceType: selectedOption.name,
          evidenceCategory: category,
          externalLink: {
            entity: 'claim',
            id: claim.claimId,
          },
          status: EvidenceStatus.New,
          evidenceFulfillment: EvidenceFulfillment.Member,
          unsolicitedEvidence: true,
        });

        const document = await uploadDocument(
          files[0],
          {
            id: evidence.id,
          },
          claim,
        );
        if (!document) {
          throw new Error('Document upload error');
        }
        await dispatch(updateEvidences(claim.claimId));
        toast('Document successfully uploaded', {
          position: 'bottom-left',
          type: 'success',
        });
        onConfirm();
      } catch {
        toast('Error uploading document', {
          position: 'bottom-left',
          type: 'error',
        });
      }
    }
  }), [options, option, category, claim, files]);

  return (
    <Modal
      {...props}
    >
      <ModalCard>
        <ModalCardHeader
          title={t('components.unsolicitedEvidenceForm.title')}
          subheader={t('components.unsolicitedEvidenceForm.subHeader')}
          titleTypographyProps={{ variant: 'h3' }}
        />
        <ModalCardContent>
          <Stack gap={2}>
            <Stack>
              <FormControl>
                <InputLabel htmlFor="category">{t('components.unsolicitedEvidenceForm.evidenceCategory')}</InputLabel>
                <Select
                  name="category"
                  id="category"
                  disabled={!categories}
                  options={categories ?? []}
                  onChange={handleSelectCategory}
                  value={category}
                  fullWidth
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="option">{t('components.unsolicitedEvidenceForm.evidenceOption')}</InputLabel>
                <Select
                  name="option"
                  id="option"
                  disabled={!options}
                  options={options?.map((opt) => ({ value: opt.name, label: opt.text })) ?? []}
                  onChange={handleSelectOption}
                  value={option}
                  fullWidth
                />
              </FormControl>
            </Stack>
            {isMobile ? <MobileUpload onFileSelect={handleFileSelect} loading={busy} /> : <FileUpload onFileSelect={handleFileSelect} loading={busy} />}
            <FileList files={files} />
          </Stack>
        </ModalCardContent>
        <ModalCardActions>
          <Stack direction="column" gap={2} alignItems="flex-start">
            <Stack
              direction={isWide ? 'row' : 'column-reverse'}
              sx={{ width: '100%' }}
              gap={2}
              alignItems={isWide ? 'flex-end' : 'none'}
              alignSelf="flex-end"
              justifyContent="end"
            >
              <Button
                variant="text"
                color="secondary"
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={option === '' || category === '' || files.length <= 0}
                loading={busy || uploading}
                onClick={handleSubmit}
              >
                {t('components.unsolicitedEvidenceForm.save')}
              </LoadingButton>
            </Stack>
          </Stack>
        </ModalCardActions>
        <ModalCloseButton aria-label={t('common.close')!} onClick={onClose}>
          <CloseIcon />
        </ModalCloseButton>
      </ModalCard>
    </Modal>
  );
}

export default UnsolicitedEvidenceModal;
