async function readFileContent(file: File): Promise<string> {
  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });

  if (!result) {
    throw new Error('File not read');
  }

  let content = result.toString().replace(/^data:(.*,)?/, '');
  if ((content.length % 4) > 0) {
    content += '='.repeat(4 - (content.length % 4));
  }
  return content;
}

function getExtensionForType(type: string): string {
  switch (type?.toLocaleLowerCase()) {
    case 'application/pdf': return '.pdf';
    case 'image/jpeg': return '.jpg';
    case 'image/png': return '.png';
    default: return '';
  }
}

export {
  getExtensionForType,
  readFileContent,
};

export default {};
