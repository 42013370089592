import { Components } from '@mui/material/styles';
import variables from '../variables';

const MuiCard: Components['MuiCard'] = {
  styleOverrides: {
    root: () => ({
      border: `1px solid ${variables.colors.secondary200}`,
      boxShadow: 'none',
    }),
  },
};

export default MuiCard;
