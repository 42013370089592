import { useState } from 'react';
import { toast } from 'react-toastify';
import documentApi from '../services/document-api';
import { ClaimEvidence } from '../services/models/claim-evidence';
import { MyClaim } from '../services/models/my-claim';
import { EvidenceDocumentResultDto } from '../services/models/evidence-document-result-dto';
import { UploadedFileDataDto } from '../services/models/uploaded-file-data-dto';

function getBucketName(url: string): string {
  const parsedUrl = new URL(url);
  return parsedUrl.hostname.split('.')[0];
}

async function uploadPreSignedDocument(file: File): Promise<UploadedFileDataDto> {
  const imageUrls = await documentApi.getPreSignedUrl([file.name]);
  await Promise.all(Object.values(imageUrls).map((url) => documentApi.uploadFileWithUrl(url, file)));
  return { bucketName: getBucketName(Object.values(imageUrls)[0]), uploadedFileKey: Object.keys(imageUrls)[0] };
}

function getEvidenceDocumentIds(evidence: ClaimEvidence): string[] {
  return evidence.documents?.map((document) => document.id) ?? [];
}

export async function updateEvidence(
  documentId: string,
  evidence: ClaimEvidence,
  claimId: string,
) {
  await documentApi.updateEvidenceDocuments(
    claimId,
    evidence.id,
    {
      evidenceId: evidence.id,
      documentIds: [...getEvidenceDocumentIds(evidence), documentId],
    },
  );
}

const useUploadEvidence = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const upload = async (
    file: File,
    content: string | null,
    uploadedFileData: UploadedFileDataDto | undefined,
    evidence: ClaimEvidence,
    claim: MyClaim,
  ): Promise<EvidenceDocumentResultDto> => {
    const result = await documentApi.uploadEvidenceDocument(claim.claimId, evidence.id, {
      evidenceType: evidence.evidenceType,
      fileContent: content,
      uploadedFileData,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      title: file.name,
      documentType: 'Evidence',
      mimeType: file.type,
    });
    return result;
  };

  const uploadDocument = async (
    file: File,
    evidence: ClaimEvidence,
    claim: MyClaim,
  ): Promise<EvidenceDocumentResultDto | undefined> => {
    setUploading(true);
    let document;
    try {
      const result = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsDataURL(file);
      });
      if (!result) {
        throw new Error('File not read');
      }

      let content = result.toString().replace(/^data:(.*,)?/, '');
      if ((content.length % 4) > 0) {
        content += '='.repeat(4 - (content.length % 4));
      }
      const uploadedFileData = await uploadPreSignedDocument(file);
      document = await upload(file, null, uploadedFileData, evidence, claim);
      await updateEvidence(document.documentId, evidence, claim.claimId);
    } catch (err) {
      toast('Error uploading document', {
        position: 'bottom-left',
        type: 'error',
      });
    } finally {
      setUploading(false);
    }
    return document;
  };

  return [uploadDocument, { uploading }] as const;
};

export default useUploadEvidence;
