import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import PaperFold from '../../../../assets/images/paper-fold.svg';

export const GuideContainer = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  minHeight: 76,
  overflow: 'visible',
  width: '100%',
  paddingRight: 0,
  position: 'relative',
  zIndex: 2,
  [theme.breakpoints.down('sm')]: {
    minHeight: 56,
  },
  '& h3': {
    fontFamily: theme.typography.body1.fontFamily,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      marginLeft: theme.spacing(2),
    },
  },
  '& button': {
    background: 'transparent',
    marginRight: theme.spacing(3),
    minWidth: 130,
    [theme.breakpoints.down('sm')]: {
      minWidth: 110,
      marginRight: 0,
    },
  },
  '&:before': {
    content: '""',
    backgroundColor: theme.palette.common.surface8,
    height: 76,
    position: 'absolute',
    right: 0,
    left: '50%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: 56,
    },
  },
  '& > div': {
    position: 'relative',
    flexGrow: 1,
    overflow: 'visible',
    '& > div': {
      backgroundColor: theme.palette.primary.main,
      flexGrow: 1,
      zIndex: 3,
      marginRight: 'auto',
    },
  },
}));

export const PaperFoldContainer = styled(Box)(({ theme }) => ({
  height: 76,
  width: 76,
  position: 'relative',
  backgroundColor: theme.palette.common.surface8,
  [theme.breakpoints.down('sm')]: {
    height: 56,
    width: 75,
  },
  '&:after': {
    content: '""',
    width: 74,
    height: 86,
    position: 'absolute',
    right: 2,
    top: 0,
    backgroundColor: 'transparent',
    backgroundImage: `url(${PaperFold})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      height: 66,
      backgroundSize: '73%',
    },
  },
}));

export default {};
