import React, { useRef, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTextField } from './signature-text-input.styles';

interface SignatureTextInputProps {
  onEnd: (signature: string | null) => void;
}

function SignatureTextInput({ onEnd }: SignatureTextInputProps) {
  const [signature, setSignature] = useState('');
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { t } = useTranslation();

  const handleClear = () => {
    setSignature('');
  };

  const handleChange = (value: string) => {
    setSignature(value);
  };

  const handleBlur = () => {
    if (canvasRef?.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.font = '40px bradley hand';
        ctx.fillText(signature, 0, 50);
        onEnd(canvasRef.current.toDataURL());
      }
    }
  };

  return (
    <Stack direction="column">
      <StyledTextField
        name="signature"
        value={signature}
        fullWidth
        onChange={(event) => handleChange(event.target.value)}
        onBlur={handleBlur}
        role="textbox"
      />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <Button
        variant="text"
        color="info"
        sx={{
          justifySelf: 'flex-end',
          alignSelf: 'flex-end',
          textDecoration: 'underline',
          color: 'common.blue',
        }}
        onClick={handleClear}
      >
        {t('components.signatureCanvas.clear')}
      </Button>
    </Stack>
  );
}

export default SignatureTextInput;
