import variables from '../variables';
import typography from '../typography';

const MuiTableHead = {
  styleOverrides: {
    root: () => ({
      '& th': {
        backgroundColor: variables.colors.surface8,
        ...typography.h5,
        fontSize: typography.body2.fontSize,
      },
    }),
  },
};

export default MuiTableHead;
