import {
  ListItem,
  ListItemButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

export const MenuListItemButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.background4,
  minHeight: 70,
  '&:hover': {
    backgroundColor: theme.palette.common.surface7,
  },
}));

export default {};
