import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { CircularProgress, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import documentApi from '../../services/document-api';
import DownloadIcon from '../../assets/icons/download-icon.svg';
import useBusyState from '../../hooks/use-busy-state';
import evidenceApi from '../../services/evidence-api';
import { EvidenceStatus } from '../../services/evidence-helpers';

export interface DownloadDocumentButtonProps extends LoadingButtonProps {
  documentId: string,
  evidenceId?: string,
  label?: string | null | undefined;
  fileName: string;
  errorMessage: string;
}

function DownloadDocumentButton({
  documentId,
  evidenceId = undefined,
  label = undefined,
  fileName,
  errorMessage,
  ...props
}: DownloadDocumentButtonProps) {
  const [loading, withLoadingState] = useBusyState();
  const { t } = useTranslation();

  const handleClick = withLoadingState(async () => {
    try {
      await documentApi.openMemberDocument(documentApi.getDocument(documentId));
      if (evidenceId) {
        evidenceApi.updateEvidenceStatus(evidenceId, EvidenceStatus.Received);
      }
    } catch {
      toast(errorMessage, {
        position: 'bottom-left',
        type: 'error',
      });
    }
  });

  if (!label) {
    return (
      <IconButton
        color="primary"
        disabled={props.disabled || !documentId}
        onClick={handleClick}
      >
        {loading ? <CircularProgress /> : <img src={DownloadIcon} width={16} alt={t('common.download')!} />}
      </IconButton>
    );
  }

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={handleClick}
      endIcon={<img src={DownloadIcon} width={16} alt={label} style={{ opacity: 0.8 }} />}
      loading={loading}
      disabled={props.disabled || !documentId}
      {...props}
    >
      {label}
    </LoadingButton>
  );
}

export default DownloadDocumentButton;
