/* eslint-disable react/no-danger */
import React from 'react';
import {
  Typography,
} from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

interface EvidenceReviewedProps {
  text?: string
}

function EvidenceReviewed({
  text = undefined,
}: EvidenceReviewedProps) {
  return (
    <Typography sx={{ display: 'flex', alignItems: 'center' }} gap={0.5}>
      <CheckCircleOutlineRoundedIcon color="success" sx={{ fontSize: 35 }} />
      {text}
    </Typography>
  );
}

export default EvidenceReviewed;
