import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormHeader = styled(Stack)(({ theme }) => ({
  borderBottom: `11px solid ${theme.palette.common.background4}`,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& h1': {
    fontFamily: theme.typography.h6.fontFamily,
  },
}));

export const FormFooter = styled(Stack)(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.common.background4}`,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

export const Form = styled('div')(({ theme }) => ({
  '& h3': {
    color: theme.palette.secondary.main,
    borderBottom: `6px solid ${theme.palette.common.background4}`,
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  '& ul': {
    listStyle: 'none',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  '& li': {
    position: 'relative',
    paddingLeft: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 6,
      left: 0,
      width: 8.5,
      height: 8.5,
      borderRadius: '50%',
      backgroundColor: theme.palette.common.secondary600,
    },
  },
  '& .MuiFormGroup-root': {
    backgroundColor: theme.palette.common.background4,
    borderRadius: 6,
    paddingLeft: theme.spacing(2),
    paddingRadius: theme.spacing(2),
  },
  '& .policy-container': {
    backgroundColor: theme.palette.common.background4,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'inline-block',
    '& > strong': {
      marginLeft: 4,
    },
  },
  '& .info-table': {
    '& td': {
      borderBottom: `4px solid ${theme.palette.common.background4}`,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
}));

export default {};
