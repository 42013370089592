import { CardProps } from '@mui/material';
import { ClaimEvidence } from './models/claim-evidence';
import { ClaimEvidenceFulfilmentGroup } from './models/claim-evidence-fulfilment-group';

export enum EvidenceStatus {
  New = 'new',
  Requested = 'requested',
  Received = 'received',
  Assessed = 'assessed',
  Waived = 'waived',
}

export const completedEvidenceStatuses = [
  EvidenceStatus.Received,
  EvidenceStatus.Assessed,
  EvidenceStatus.Waived,
];

export const totalEvidenceStatuses = [
  ...completedEvidenceStatuses,
  EvidenceStatus.Requested,
];

export function getEvidenceCounts(evidences: ClaimEvidence[]) {
  return evidences.reduce(
    (acc, evidence) => {
      const status = evidence.status?.toLowerCase() as EvidenceStatus;

      if (completedEvidenceStatuses.includes(status)) {
        acc.completedCount += 1;
      }

      if (totalEvidenceStatuses.includes(status)) {
        acc.totalCount += 1;
      }

      return acc;
    },
    { completedCount: 0, totalCount: 0 },
  );
}

export function checkEvidenceCompletion(evidence: ClaimEvidence) {
  const status = evidence.status?.toLowerCase() as EvidenceStatus;
  return completedEvidenceStatuses.includes(status);
}

export interface EvidencePanelProps extends CardProps {
  title: string
  descriptions: string[]
  complete?: boolean
}

export function getEvidenceByGroup(evidences: ClaimEvidence[], group: ClaimEvidenceFulfilmentGroup): ClaimEvidence[] {
  // We have to split fulfilmentType by ' or ' as for some reason it's returned as a string with or for multiple types.
  return evidences.filter((evidence) => evidence.evidenceFulfillment && group.fulfilmentType.split(' or ').includes(evidence.evidenceFulfillment));
}
