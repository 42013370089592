import React, { useCallback } from 'react';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FolderIcon from '@mui/icons-material/Folder';
import { mbToByte } from '../../utils/converters';

const MAX_FILE_SIZE = 50;

interface EvidenceUploadProps {
  onFileSelect: (files: File[]) => void;
}

function FileInput({
  onFileSelect,
}: EvidenceUploadProps) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    onFileSelect(acceptedFiles);
    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          const errorMsg = error && error.code === 'file-too-large' ? `File ${file.name} is too large. Maximum size is ${MAX_FILE_SIZE}MB.` : null;
          toast(errorMsg ?? error.message, {
            position: 'bottom-left',
            type: 'error',
          });
        });
      });
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: true,
    maxSize: mbToByte(MAX_FILE_SIZE),
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
      'application/msword': ['.doc', '.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-outlook': ['.msg'],
      'message/rfc822': ['.eml'],
    },
  });

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      {...getRootProps({
        isFocused,
        isDragAccept,
        isDragReject,
        role: 'none',
      })}
    >
      <input {...getInputProps()} />
      <Stack alignItems="center">
        <FolderIcon />
        <Box sx={(theme) => ({ ...theme.typography.body2, color: 'info.main', textDecoration: 'underline' })}>{t(('components.fileUpload.chooseFile'))}</Box>
        <Typography variant="caption" sx={(theme) => ({ color: theme.palette.common.secondary600 })}>{`(${t('components.fileUpload.maxFileSize')} ${MAX_FILE_SIZE}mb)`}</Typography>
      </Stack>
    </Box>
  );
}

export default FileInput;
