import { Components } from '@mui/material/styles';
import variables from '../variables';

const MuiRadio: Components['MuiRadio'] = {
  styleOverrides: {
    root: () => ({
      color: variables.colors.greyLight,
      '&.Mui-checked': {
        color: variables.colors.green,
      },
    }),
  },
};

export default MuiRadio;
