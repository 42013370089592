import { Auth } from 'aws-amplify';
import axios, {
  AxiosRequestHeaders,
  AxiosResponse,
  RawAxiosRequestConfig,
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export const instance = axios.create({
  responseType: 'json',
  headers: {
    'Content-Type': 'application/problem+json',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': true,
    'Cache-Control': 'no-cache',
  },
});

instance.interceptors.request.use(async (config) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.accessToken.jwtToken;
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    } as AxiosRequestHeaders,
  };
}, (error) => Promise.reject(error));

export const handleError = (e: unknown) => {
  if (axios.isAxiosError(e)) {
    return e.response?.data;
  }
  if (e instanceof Error) {
    return e.message;
  }
  return e;
};

function extractResult<T>(response: AxiosResponse<T>): T {
  return response.data;
}

export function query(params?: QueryParamsType): RawAxiosRequestConfig {
  return { params };
}

async function get<R>(path: string, q?: QueryParamsType): Promise<R> {
  return instance.get<R>(path, query(q)).then(extractResult);
}

async function post<D, R>(path: string, data: D, config?: RawAxiosRequestConfig): Promise<R> {
  return instance.post<R>(path, data, config).then(extractResult);
}

async function put<D, R>(path: string, data: D, config?: RawAxiosRequestConfig): Promise<R> {
  return instance.put<R>(path, data, config).then(extractResult);
}

async function patch<D, R>(path: string, data: D, config?: RawAxiosRequestConfig): Promise<R> {
  return instance.patch<R>(path, data, { headers: { 'Content-Type': 'application/json-patch+json' }, ...config }).then(extractResult);
}

async function deleteRequest<R>(path: string, config?: RawAxiosRequestConfig): Promise<R> {
  return instance.delete<R>(path, config).then(extractResult);
}

const http = {
  get,
  patch,
  put,
  post,
  delete: deleteRequest,
};

export default http;
