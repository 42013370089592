/* eslint-disable max-len  */
import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useController } from 'react-hook-form';
import { Form } from '../evidence-form.styles';

export interface MedicalPermissionsFormProps {
  name: string;
  dateOfBirth?: string | null | undefined;
  claimId: string;
  policyId: string;
}

function MedicalPermissionsForm({
  name,
  dateOfBirth = undefined,
  claimId,
  policyId,
}: MedicalPermissionsFormProps) {
  const {
    field,
  } = useController({ name: 'reviewThirdPartyEvidence' });
  return (
    <Form>
      <table>
        <tbody>
          <tr>
            <td valign="top">
              <table className="info-table" cellPadding="0" cellSpacing="0" width="100%">
                <tbody>
                  <tr>
                    <td width="50%">Full name</td>
                    <td>
                      <strong>
                        {name}
                      </strong>
                    </td>
                  </tr>
                  {dateOfBirth && (
                    <tr>
                      <td>Date of birth</td>
                      <td><strong>{dateOfBirth}</strong></td>
                    </tr>
                  )}
                  <tr>
                    <td>Your claim number</td>
                    <td><strong>{claimId}</strong></td>
                  </tr>
                  <tr>
                    <td>Your policy number</td>
                    <td><strong>{policyId}</strong></td>
                  </tr>
                </tbody>
              </table>

              <p><strong>Please read the following declaration and consent carefully and sign at the bottom to agree to the terms:</strong></p>
              <h3>Your right under the Access to Medical Reports Act 1988</h3>

              <p>To assess your claim, we may need to obtain medical reports and your medical records from your doctor and other medical specialists involved in your treatment. However, under the Access to Medical Reports Act 1988 you have certain rights that we have summarised below:</p>
              <ul>
                <li>You may withhold your consent (but if you do, we may be unable to proceed with your claim).</li>
                <li>You have the right to see the report before it is sent to us provided that you apply to the doctor within 21 days. If you choose not to see the report at this stage, you may ask the doctor for a copy within 6 months of it being sent to us. The doctor may charge you a fee for supplying the report.</li>
                <li>You can ask the doctor to amend any part of the report which you consider to be incorrect or misleading and if the doctor does not agree you may append your comments.</li>
                <li>The doctor can withhold part or all the report from you if he has reasons why he thinks you should not see it.</li>
              </ul>

              <p>
                Further information about your rights to access reports can be found at:
                <a href="//www.legislation.gov.uk" target="_blank" rel="noreferrer">
                  www.legislation.gov.uk
                </a>
                <br />
                <strong>Important: </strong>
                if you do wish to see your medical report before it is sent to us, you must amend the consent below.
              </p>
              <div>
                <FormGroup>
                  <FormControlLabel {...field} control={<Checkbox />} label="Only check this box if you require to see any medical report prior to it being sent to the Exeter." />
                </FormGroup>
              </div>

              <h3>Your consent</h3>

              <strong>Please read the following consent carefully and sign below to agree to the terms:</strong>
              <ul>
                <li>I consent to The Exeter seeking information from HM Revenue & Customs, the Department of Work & Pensions and my employer, other insurers and accountant (if applicable) or from any other source in connection with the assessment of my claim.</li>
                <li>I consent to The Exeter using my medical records and other personal information for the purpose of claim assessment, fraud prevention and reinsurance and understand other parties may need to receive the information obtained to handle my claim.</li>
                <li>I agree that my personal data may be shared with other agents or databases for the purposes of preventing and detecting fraud.</li>
                <li>I agree that a copy of this consent shall have the validity of the original.</li>
                <li>I understand my rights under the Access to Medical Reports Act 1988 and agree to the provision of my medical records being released to The Exeter for the purposes of assessing my claim.</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
}

export default MedicalPermissionsForm;
