import type { } from '@mui/x-date-pickers/themeAugmentation';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { createTheme } from '@mui/material/styles';
import components from './components';
import palette from './palette';
import typography from './typography';

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    infoBar: CSSProperties;
    mainHorizontalSpacing: number;
    logo: CSSProperties;
    skeletonRowHeight: number;
  }
}

const defaultTheme = createTheme();
defaultTheme.shadows[24] = '0px 0px 10px 1px rgba(0, 0, 0, 0.05)';

const theme = createTheme({
  components,
  mixins: {
    toolbar: {
      ...defaultTheme.mixins.toolbar,
    },
    infoBar: {
      height: 90,
      minHeight: 90,
    },
    logo: {
      width: 150,
    },
    skeletonRowHeight: 363,
    mainHorizontalSpacing: 10,
  },
  palette,
  shadows: defaultTheme.shadows,
  typography,
});

export default theme;
