const typography = {
  fontFamily: '"FsMeRegular", sans-serif',
  fontSize: 16,
  h1: {
    fontFamily: '"FsMeHeavy"',
    fontSize: '2rem ', // 32px
    lineHeight: 1.28, // 54px
  },
  h2: {
    fontFamily: '"FsMeBold"',
    fontSize: '1.5rem ', // 24px
    lineHeight: 1.25, // 25px
  },
  h3: {
    fontFamily: '"FsMeBold"',
    fontSize: '1.25rem ', // 20px
    lineHeight: 1.25, // 25px
  },
  h4: {
    fontFamily: '"FsMeBold"',
    fontSize: '1.06rem ', // 17px
    lineHeight: 1.25, // 25px
  },
  h5: {
    fontFamily: '"FsMeBold"',
    fontSize: '1rem ', // 16px
    lineHeight: 1.22, // 22px
  },
  h6: {
    fontFamily: '"FsMeLight"',
    fontSize: '1.25rem ', // 20px
    lineHeight: 1.22, // 22px
  },
  body1: {
    fontFamily: '"FsMeRegular", sans-serif',
    fontSize: '1rem', // 16px
  },
  body2: {
    fontFamily: '"FsMeRegular", sans-serif',
    fontSize: '0.875rem', // 14px
  },
  subtitle1: {
    fontSize: '0.75rem', // 12px
  },
  caption: {
    fontSize: '0.75rem', // 12px
  },
} as const;

export default typography;
