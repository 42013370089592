import React from 'react';
import {
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.common.surface5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#41ab45',
  },
}));

export interface TodoListProgressProps {
  completed: number;
  total: number;
}

function TodoListProgress({ completed, total }: TodoListProgressProps) {
  const { t } = useTranslation();

  if (!total || total <= 0) {
    return null;
  }

  return (
    <Stack gap={1} data-testid="todo-list-progress">
      <Typography variant="h3">
        {`${t('components.claimDetail.todoList')} `}
        <Typography component="span" fontSize="1.25rem" lineHeight={1.25}>
          {t('components.claimDetail.todoListProgress', { completed, total })}
        </Typography>
      </Typography>
      <Typography mb={2} variant="body1">{t('components.claimDetail.todoListDescription')}</Typography>
      <CustomLinearProgress variant="determinate" value={(completed / total) * 100} />
    </Stack>
  );
}

export default TodoListProgress;
