import React from 'react';
import {
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';
import SignatureForm, { SignatureData, SignatureType } from './signature-form';
import { DrawerCard } from './signature-modal.styles';

interface SignatureDrawerProps extends Omit<SwipeableDrawerProps, 'onClose' | 'onOpen' | 'children'> {
  acceptedTypes?: SignatureType[],
  onClose: () => void;
  onConfirm: (signatureData: SignatureData) => void;
}

function SignatureDrawer({
  onClose,
  onConfirm,
  acceptedTypes = [SignatureType.DRAW, SignatureType.UPLOAD],
  ...props
}: SignatureDrawerProps) {
  return (
    <SwipeableDrawer
      {...props}
      onOpen={() => {}}
      onClose={onClose}
      anchor="bottom"
    >
      <DrawerCard>
        <SignatureForm
          acceptedTypes={acceptedTypes}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      </DrawerCard>
    </SwipeableDrawer>
  );
}

export default SignatureDrawer;
