import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UnsolicitedEvidenceModal from './unsolicited-evidence-modal';
import { MyClaim } from '../../../../services/models/my-claim';
import { MemberDto } from '../../../../services/models/member-dto';

interface UnsolicitedEvidenceProps {
  claim: MyClaim;
  member: MemberDto;
}

function UnsolicitedEvidence({ claim, member }: UnsolicitedEvidenceProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleConfirm = () => {
    setOpen(false);
  };

  return (
    <Stack gap={2} alignItems="flex-start">
      <Typography variant="h3">{t('components.unsolicitedEvidence.title')}</Typography>
      <Typography variant="body1">{t('components.unsolicitedEvidence.description')}</Typography>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t('components.unsolicitedEvidence.addEvidence')}
      </Button>
      {open && (
        <UnsolicitedEvidenceModal
          claim={claim}
          member={member}
          open={open}
          onClose={() => setOpen(false)}
          onConfirm={handleConfirm}
        />
      )}
    </Stack>
  );
}

export default UnsolicitedEvidence;
