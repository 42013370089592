import React, { ReactNode } from 'react';
import {
  StackProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { DataDisplayContainer } from './data-display.styles';

export interface DataDisplayProps extends StackProps {
  label: string
  value: string | ReactNode
  labelProps?: TypographyProps
  valueProps?: TypographyProps
  orientation?: 'horizontal' | 'vertical'
}

function DataDisplay({
  label,
  value,
  labelProps = {},
  valueProps = {},
  orientation = 'vertical',
  ...props
}: DataDisplayProps) {
  return (
    <DataDisplayContainer orientation={orientation} {...props} role="listitem">
      <Typography variant="body2" {...labelProps}>
        {label}
      </Typography>
      <Typography variant="h5" component="span" {...valueProps}>
        {value}
      </Typography>
    </DataDisplayContainer>
  );
}

export default DataDisplay;
