import typography from '../typography';

const MuiStepLabel = {
  styleOverrides: {
    root: () => ({
      '& .MuiStepLabel-label': {
        ...typography.h4,
      },
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 10,
      },
    }),
  },
};

export default MuiStepLabel;
