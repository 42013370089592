import { Components } from '@mui/material/styles';

const MuiInputAdornment: Components['MuiInputAdornment'] = {
  styleOverrides: {
    positionStart: () => ({
      marginLeft: 8,
      marginRight: 0,
    }),
    positionEnd: () => ({
      marginLeft: 0,
      marginRight: 8,
    }),
  },
};

export default MuiInputAdornment;
