import { Components } from '@mui/material/styles';
import variables from '../variables';
import typography from '../typography';

const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: () => ({
      fontSize: 16,
      borderRadius: 4,
      borderWidth: 1,
      border: `1px solid ${variables.colors.surface3}`,

      '&:has(input:focus)': {
        border: `1px solid ${variables.colors.greyDark}`,
      },
      '& > input': {
        paddingTop: 9,
        paddingBottom: 9,
        ...typography.body1,
        color: variables.colors.greyDark,
        padding: '9px 12px',
        height: 25,
      },
      '& > textarea': {
        ...typography.body1,
        color: variables.colors.greyDark,
        padding: '5px 12px',
      },
      '& fieldset': {
        border: 'none',
      },
      '& fieldset > legend': {
        display: 'none',
      },
    }),
  },
};

export default MuiInputBase;
