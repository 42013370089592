import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import typography from '../../../../theme/typography';
import { shouldForwardProp } from '../../../../components/types';

type EvidenceCardProps = {
  complete?: boolean;
};

export const EvidenceCard = styled(Card, {
  shouldForwardProp: (prop) => shouldForwardProp<EvidenceCardProps>(['complete'], prop),
})<EvidenceCardProps>(({ theme, complete }) => ({
  backgroundColor: complete ? theme.palette.common.background3 : theme.palette.common.background4,
  border: 'none',
  '& .MuiCardHeader-root': {
    borderBottom: 'none',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  '& .MuiCardHeader-title': {
    ...typography.h3,
  },
  '& .MuiCardHeader-subheader': {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h6.fontFamily,
    '& > div': {
      borderBottom: `1px solid ${complete ? '#BFDAD2' : theme.palette.common.surface5}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  '& .MuiCardContent-root': {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(0),
    },
  },
  '& button:not(.MuiIconButton-root)': {
    minWidth: 164,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default {};
