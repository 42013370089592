import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import HeroImg from '../../assets/images/global/document-hero.png';

export const WelcomeContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface9,
  backgroundImage: `url(${HeroImg})`,
  backgroundPositionX: `calc(100% - ${theme.mixins.logo.width}px)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 100%',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: 120,
  padding: 0,
  paddingLeft: theme.mixins.logo.width,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(4),
    backgroundPositionX: 'calc(100% + 190px)',
  },
  [theme.breakpoints.down('md')]: {
    backgroundPositionX: 'calc(100% + 290px)',
    minHeight: 160,
    paddingLeft: theme.spacing(2),
    '& > h1': {
      maxWidth: 350,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > h1': {
      maxWidth: 250,
    },
  },
  '& h1': {
    fontFamily: theme.typography.h6.fontFamily,
  },
}));

export default WelcomeContainer;
