import React, { ChangeEvent, useCallback } from 'react';
import {
  Box,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { CameraButton, VisuallyHiddenInput } from './camera-input.styles';

interface EvidenceUploadProps {
  onFileSelect: (file: File) => void;
}

function CameraInput({
  onFileSelect,
}: EvidenceUploadProps) {
  const { t } = useTranslation();

  const handleFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      onFileSelect(file);
    }
  }, []);

  return (
    <CameraButton
      component="label"
      role={undefined}
      variant="text"
      color="inherit"
      tabIndex={-1}
      disableFocusRipple
      disableRipple
    >
      <VisuallyHiddenInput type="file" accept="image/*" capture="environment" onChange={handleFileChange} />
      <Stack gap={0} alignItems="center">
        <CameraAltIcon />
        <Box sx={{ color: 'info.main', textDecoration: 'underline' }}>{t(('components.mobileUpload.takePhoto'))}</Box>
      </Stack>
    </CameraButton>
  );
}

export default CameraInput;
