import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { shouldForwardProp } from '../types';

type DataDisplayContainerProps = {
  orientation?: 'horizontal' | 'vertical'
};

export const DataDisplayContainer = styled(Stack, {
  shouldForwardProp: (prop) => shouldForwardProp<DataDisplayContainerProps>(['orientation'], prop),
})<DataDisplayContainerProps>(({ theme, orientation }) => {
  const isVertical = orientation === 'vertical';

  if (isVertical) {
    return {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 90,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    };
  }

  return {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    minHeight: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
  };
});

export default {};
