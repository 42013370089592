import React, { useState, useEffect, useCallback } from 'react';
import {
  Alert, Button, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import DocumentsLayout from '../../components/documents-layout';
import DocumentsListContainer from '../../components/documents-list-container';
import documentApi from '../../services/document-api';
import { DashboardPage } from '../pages';
import useBusyState from '../../hooks/use-busy-state';
import Loader from '../../components/loader';

enum DocumentError {
  NOT_FOUND,
  DEFAULT,
}

function Document() {
  const { t } = useTranslation();
  const { documentId } = useParams();
  const [busy, withBusyState] = useBusyState();
  const navigate = useNavigate();
  const [errorType, setErrorType] = useState<DocumentError>();
  const [success, setSuccess] = useState(false);

  const downloadDocument = useCallback(withBusyState(async () => {
    if (!documentId) return;
    setErrorType(undefined);
    try {
      await documentApi.openMemberDocument(documentApi.getDocument(documentId));
      toast(t('components.document.toast.success'), {
        position: 'bottom-left',
        type: 'success',
      });
      setSuccess(true);
    } catch (error: any) {
      if (error.response.status === 403 || error.response.status === 404) {
        setErrorType(DocumentError.NOT_FOUND);
      } else {
        setErrorType(DocumentError.DEFAULT);
        toast(t('components.document.toast.error'), {
          position: 'bottom-left',
          type: 'error',
        });
      }
      setSuccess(false);
    }
  }), [documentId]);

  useEffect(() => {
    downloadDocument();
  }, [documentId]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (success) {
      timeout = setTimeout(() => {
        navigate(DashboardPage.path, { replace: true });
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [success]);

  if (busy) {
    return <Loader />;
  }

  const renderContent = () => {
    if (!busy && success) {
      return <Typography>{t('components.document.redirecting')}</Typography>;
    }

    if (errorType === DocumentError.NOT_FOUND) {
      return <Alert severity="warning">{t('components.document.documentNotFound')}</Alert>;
    }

    if (errorType === DocumentError.DEFAULT) {
      return (
        <Stack gap={2}>
          <Typography>{t('components.document.failed')}</Typography>
          <Button variant="contained" onClick={downloadDocument}>{t('common.tryAgain')}</Button>
        </Stack>
      );
    }

    return null;
  };

  return (
    <DocumentsLayout>
      <DocumentsListContainer>
        {renderContent()}
      </DocumentsListContainer>
    </DocumentsLayout>
  );
}

export default Document;
