import { useCallback, useState } from 'react';
import RequestTracker from '../services/request-tracker';

export default function useBusyState(tracker?: RequestTracker) {
  const [busy, setBusy] = useState(false);
  const withBusyState = useCallback(<T extends (...args: any) => any>(callback?: T) => async (...args: Parameters<T>) => {
    if (!callback) {
      return;
    }
    setBusy(true);
    try {
      await callback(...args);
    } finally {
      setBusy(tracker ? tracker.requestCount > 0 : false);
    }
  }, [setBusy, tracker]);
  return [busy, withBusyState] as const;
}
