import React, { ReactNode } from 'react';
import InfoCard from '../../../../components/info-card';

interface ErrorPanelProps {
  title: string;
  children: ReactNode | string;
}

function ErrorPanel({ title, children }: ErrorPanelProps) {
  return (
    <InfoCard headerTitle={title}>
      {children}
    </InfoCard>
  );
}

export default ErrorPanel;
