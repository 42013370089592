/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { GuideContainer, PaperFoldContainer } from './claim-guide-banner.styles';
import { Container } from '../../../claims/claims.styles';
import DownloadIcon from '../../../../assets/icons/download-icon.svg';
import useBreakpoint from '../../../../hooks/use-breakpoints';
import useBusyState from '../../../../hooks/use-busy-state';

interface ClaimGuideBannerProps {
  onDownload: () => void;
}

function ClaimGuideBanner({ onDownload }: ClaimGuideBannerProps) {
  const { t } = useTranslation();
  const isWide = useBreakpoint('sm');
  const [busy, withBusyState] = useBusyState();

  const handleClick = useCallback(withBusyState(async () => {
    await onDownload();
  }), [onDownload]);

  return (
    <GuideContainer data-testid="claim-guide-banner">
      <Container sx={{ justifyContent: 'center' }}>
        <Stack direction="row" alignItems="center" gap={isWide ? 6 : 4}>
          <Typography variant="h3">
            {isWide ? t('components.claimGuideBanner.title') : t('components.claimGuideBanner.shortTitle')}
          </Typography>
          <LoadingButton
            color="secondary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt={t('common.download')!} width={16} height={16} />}
            size={isWide ? 'medium' : 'small'}
            loading={busy}
            onClick={handleClick}
            data-testid="download-claim-guide"
          >
            {t('common.download')}
          </LoadingButton>
          <PaperFoldContainer />
        </Stack>
      </Container>
    </GuideContainer>
  );
}

export default ClaimGuideBanner;
