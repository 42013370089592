import typography from '../typography';

const MuiDialogTitle = {
  styleOverrides: {
    root: () => ({
      ...typography.h4,
    }),
  },
};

export default MuiDialogTitle;
