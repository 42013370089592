import { Components } from '@mui/material';
import typography from '../typography';
import variables from '../variables';

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: () => ({
      ...typography.body1,
      color: variables.colors.greyDark,
      lineHeight: 3,
      margin: 0,
      display: 'flex',
      flex: '0 0 100%',
      '&.Mui-error': {
        color: variables.colors.greyDark,
      },
    }),
  },
};

export default MuiFormHelperText;
