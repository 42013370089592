import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const VisuallyHiddenInput = styled('input')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
}));

export const CameraButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  '&:hover': {
    backgroundColor: 'inherit',
  },
})) as typeof Button;

export default {};
