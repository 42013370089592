import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ErrorPanel from './components/error-panel';

function ErrorCatch() {
  const { t } = useTranslation();
  return (
    <ErrorPanel
      title={t('errors.fallback.title')}
    >
      <Typography variant="body1">
        {t('errors.fallback.description')}
      </Typography>
    </ErrorPanel>
  );
}

export default ErrorCatch;
