import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FileUpload from '../../../../components/file-upload';
import { ClaimEvidence } from '../../../../services/models/claim-evidence';
import useUploadEvidence from '../../../../hooks/use-upload-evidence';
import { MyClaim } from '../../../../services/models/my-claim';
import FileList from './file-list';
import useMobileDetect from '../../../../hooks/use-mobile-detect';
import MobileUpload from '../../../../components/mobile-upload';
import { useAppDispatch } from '../../../../store/hooks';
import { updateEvidences } from '../../../../features/ClaimDetailSlice';
import useBusyState from '../../../../hooks/use-busy-state';

export interface EvidenceUploadProps {
  claim: MyClaim,
  evidence: ClaimEvidence,
}

function EvidenceUpload({
  claim,
  evidence,
}: EvidenceUploadProps) {
  const [uploadDocument, { uploading }] = useUploadEvidence();
  const [busy, withBusyState] = useBusyState();
  const [files, setFiles] = useState<File[]>([]);
  const isMobile = useMobileDetect();
  const dispatch = useAppDispatch();

  const handleFileSelect = withBusyState(async (newFiles: File[]) => {
    try {
      const file = newFiles[0];
      const document = await uploadDocument(
        file,
        evidence,
        claim,
      );
      if (!document) {
        throw new Error('Document not uploaded');
      }
      setFiles((state) => [...state, file]);
      // Add timeout to allow status to propergate on the b/e
      await new Promise((res) => {
        setTimeout(res, 4000);
      });
      await dispatch(updateEvidences(claim.claimId));
    } catch {
      toast('Error uploading document', {
        position: 'bottom-left',
        type: 'error',
      });
    }
  });

  return (
    <>
      {isMobile ? (
        <MobileUpload onFileSelect={handleFileSelect} loading={uploading || busy} />
      ) : (
        <FileUpload onFileSelect={handleFileSelect} loading={uploading || busy} />
      )}
      <FileList files={files} />
    </>
  );
}

export default EvidenceUpload;
