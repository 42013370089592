import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 15,
  borderWidth: 2,
  borderRadius: 5,
  borderColor: theme.palette.common.secondary200,
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '100%',
}));

export default Container;
