import variables from '../variables';

const MuiTooltip = {
  styleOverrides: {
    tooltip: () => ({
      backgroundColor: variables.colors.surface1,
      color: variables.colors.white,
      padding: '12px 16px',
    }),
    arrow: () => ({
      '&:before': {
        backgroundColor: variables.colors.surface1,
        color: variables.colors.white,
      },
    }),
  },
};

export default MuiTooltip;
