import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpStatusCode, isAxiosError } from 'axios';
// eslint-disable-next-line import/no-cycle
import { AppDispatch, RootState } from '../store';
import { MyDocument } from '../services/models/my-document';
// eslint-disable-next-line import/no-cycle
import documentApi from '../services/document-api';
import { MemberDto } from '../services/models/member-dto';
import memberApi from '../services/member-api';

export const NONE = { status: 'none' } as const;
export const LOADING = { status: 'loading' } as const;
export const NOT_FOUND = { status: 'notFound' } as const;
export type AvailableMember = {
  status: 'available';
  documents: MyDocument[];
  member: MemberDto;
};

export type MemberState =
  typeof NONE |
  typeof LOADING |
  typeof NOT_FOUND |
  AvailableMember;

export const MEMBER_SLICE_NAME = 'MEMBER';

/* eslint-disable no-param-reassign */
export const memberSlice = createSlice({
  name: MEMBER_SLICE_NAME,
  initialState: NONE as MemberState,
  reducers: {
    reset() {
      return NONE;
    },
    startLoading() {
      return LOADING;
    },
    updated(state, { payload }: PayloadAction<MemberState>) {
      return payload;
    },
    memberUpdated(state, { payload }: PayloadAction<MemberDto>) {
      if (state.status === 'available') {
        state.member = payload;
      }
    },
    documentsUpdated(state, { payload }: PayloadAction<MyDocument[]>) {
      if (state.status === 'available') {
        state.documents = payload;
      }
    },
  },
});
/* eslint-enable no-param-reassign */

export const {
  documentsUpdated,
  memberUpdated,
  reset,
  startLoading,
  updated,
} = memberSlice.actions;

export const selectMemberState = (state: RootState) => state.member;
export const selectMemberStateMember = (state: RootState) => state.member as AvailableMember;

export const loadMember = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.member.status !== 'available') {
    dispatch(updated(LOADING));
    try {
      const member = await memberApi.getMember();
      const documents = await documentApi.getMemberDocuments();
      dispatch(updated({
        status: 'available',
        documents,
        member,
      }));
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === HttpStatusCode.NotFound) {
        dispatch(updated(NOT_FOUND));
      } else {
        dispatch(updated(NONE));
        throw e;
      }
    }
  }
};

export const getMember = () => async (dispatch: AppDispatch) => {
  dispatch(updated(LOADING));
  try {
    const member = await memberApi.getMember();
    dispatch(memberUpdated(member));
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === HttpStatusCode.NotFound) {
      dispatch(updated(NOT_FOUND));
    } else {
      dispatch(updated(NONE));
      throw e;
    }
  }
};

export const getDocuments = () => async (dispatch: AppDispatch) => {
  dispatch(updated(LOADING));
  try {
    const documents = await documentApi.getMemberDocuments();
    dispatch(documentsUpdated(documents));
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === HttpStatusCode.NotFound) {
      dispatch(updated(NOT_FOUND));
    } else {
      dispatch(updated(NONE));
      throw e;
    }
  }
};
