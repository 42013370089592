import { Components } from '@mui/material';
import typography from '../typography';

const MuiInputLabel: Components['MuiInputLabel'] = {
  defaultProps: {
    shrink: false,
  },
  styleOverrides: {
    root: () => ({
      ...typography.body1,
      lineHeight: 3,
      fontWeight: 'normal',
      position: 'relative',
      transform: 'none',
    }),
  },
};

export default MuiInputLabel;
