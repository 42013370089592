const MuiTabs = {
  styleOverrides: {
    root: () => ({
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& button': {
        marginRight: 8,
      },
    }),
  },
};

export default MuiTabs;
