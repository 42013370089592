import React, { ReactNode, PropsWithChildren } from 'react';
import { CardProps } from '@mui/material/Card';
import { Variant } from '@mui/material/styles/createTypography';
import { InfoCard as InfoCardStyled, InfoCardHeader, InfoCardContent } from './info-card.styles';

export interface InfoCardProps extends PropsWithChildren<CardProps> {
  headerTitle?: ReactNode | string | null;
  headerVariant?: Variant;
}

function InfoCard({
  headerTitle = null,
  headerVariant = 'h3',
  children,
  ...otherProps
}: InfoCardProps) {
  return (
    <InfoCardStyled {...otherProps} elevation={0}>
      {headerTitle && (
        <InfoCardHeader
          title={headerTitle}
          titleTypographyProps={{
            variant: headerVariant,
            component: 'h3',
          }}
        />
      )}
      <InfoCardContent>
        {children}
      </InfoCardContent>
    </InfoCardStyled>
  );
}

export default InfoCard;
