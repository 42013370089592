import React, { PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClaimListType } from '../../../../services/claims-helpers';

interface ClaimsLayoutProps {
  type: ClaimListType;
}

function ClaimsList({
  type,
  children = undefined,
}: PropsWithChildren<ClaimsLayoutProps>) {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Typography variant="body1" component="h3">
        {type === 'Previous' ? t('components.claims.claimsList.previousHeader') : t('components.claims.claimsList.activeHeader')}
      </Typography>
      <Stack direction="column" gap={2}>
        {children}
      </Stack>
    </Stack>
  );
}

export default ClaimsList;
