import React from 'react';
import {
  Modal,
  ModalProps,
} from '@mui/material';
import SignatureForm, { SignatureData, SignatureType } from './signature-form';
import { ModalCard } from './signature-modal.styles';

interface SignatureModalProps extends Omit<ModalProps, 'onClose' | 'children'> {
  acceptedTypes?: SignatureType[],
  onClose: () => void;
  onConfirm: (signatureData: SignatureData) => void;
}

function SignatureModal({
  onClose,
  onConfirm,
  acceptedTypes = [SignatureType.DRAW, SignatureType.UPLOAD],
  ...props
}: SignatureModalProps) {
  return (
    <Modal
      {...props}
    >
      <ModalCard>
        <SignatureForm
          acceptedTypes={acceptedTypes}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      </ModalCard>
    </Modal>
  );
}

export default SignatureModal;
