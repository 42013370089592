import { PaletteOptions } from '@mui/material';
import variables from './variables';

const palette = {
  background: {
    default: variables.colors.background,
  },
  common: {
    ...variables.colors,
  },
  error: {
    main: variables.colors.red,
  },
  warning: {
    main: variables.colors.orange,
  },
  primary: {
    main: variables.colors.primary,
  },
  secondary: {
    main: variables.colors.secondary,
  },
  success: {
    main: variables.colors.green,
  },
  text: {
    primary: variables.colors.greyDark,
    secondary: variables.colors.greyLight,
  },
} as PaletteOptions;

export default palette;
