import { useCallback, useEffect, useState } from 'react';
import evidenceApi from '../services/evidence-api';
import { OptionDto } from '../services/models/option-dto';

export default function useOptionList(branch: string, tag: string, name: string) {
  const [categories, setCategories] = useState<string[]>();
  const [options, setOptions] = useState<OptionDto[]>();

  const init = useCallback(async () => {
    const response = await evidenceApi.getOptionListCategories(branch, tag, name);
    setCategories(response);
  }, [branch, tag, name]);

  const getCategoryOptions = useCallback(async (category: string) => {
    const response = await evidenceApi.getOptionListCategoryOptions(branch, tag, name, category);
    setOptions(response);
  }, [branch, tag, name]);

  useEffect(() => {
    init();
  }, []);

  return [categories, options, getCategoryOptions] as const;
}
