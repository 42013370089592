import { styled } from '@mui/material/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@mui/material';

export const DocumentCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#FAFAFA',
  border: '1px solid #EEEEEE',
  borderLeft: '5px solid #E4DDF0',
  borderRadius: 0,
  cursor: 'pointer',
  padding: theme.spacing(1),
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    boxShadow: 'box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25)',
    '& img': {
      filter: 'grayscale(0)',
    },
  },
}));

export const DocumentCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(0.5),
  border: 'none',
  '& .MuiAvatar-root': {
    width: 21,
    height: 21,
    fontSize: 8,
  },
  '& .MuiCardHeader-title': {
    ...theme.typography.body1,
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.common.textDark,
  },
}));

export const DocumentCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  '&:last-child': {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  '& p': {
    color: theme.palette.common.textDark,
    fontFamily: theme.typography.h6.fontFamily,
    whiteSpace: 'pre-line',
  },
}));

export const DocumentCardActions = styled(CardActions)(({ theme }) => ({
  justifyContent: 'flex-end',
  minHeight: 'auto',
  padding: theme.spacing(1),
  '& > img': {
    filter: 'grayscale(1)',
  },
}));

export default {};
