import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import useBreakpoint from '../../hooks/use-breakpoints';

function DocumentsListContainer({ children }: PropsWithChildren) {
  const isWide = useBreakpoint('sm');

  return (
    <Stack gap={2} direction={isWide ? 'row' : 'column'} display="flex" flexWrap="wrap">
      {children}
    </Stack>
  );
}

export default DocumentsListContainer;
