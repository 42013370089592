import variables from '../variables';

const MuiChip = {
  styleOverrides: {
    root: () => ({
      '&.Mui-checked': {
        color: variables.colors.green,
      },
    }),
  },
};

export default MuiChip;
