import React, { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClaimEvidence } from '../../../../services/models/claim-evidence';
import { EvidenceFulfillment } from '../../../../services/models/evidence-fulfillment';
import CompleteEvidencePanel from './complete-evidence-panel';
import SignEvidencePanel from './sign-evidence-panel';
import EmailEvidencePanel from './email-evidence-panel';
import ChaseEvidencePanel from './chase-evidence-panel';
import { ClaimEvidencePage } from '../../../pages';
import { MyClaim } from '../../../../services/models/my-claim';
import { EvidenceStatus } from '../../../../services/models/evidence-status';
import documentApi from '../../../../services/document-api';

enum EvidenceAction {
  VIEW,
  DOWNLOAD,
}

function getEvidencePanel(evidence: ClaimEvidence, claim: MyClaim, action: (evidenceAction?: EvidenceAction) => unknown) {
  const complete = evidence.status === EvidenceStatus.Received || evidence.status === EvidenceStatus.Assessed;
  switch (evidence.evidenceFulfillment) {
    case EvidenceFulfillment.MemberSupplyOnly: return (
      <CompleteEvidencePanel
        evidence={evidence}
        complete={complete}
        onView={() => action(EvidenceAction.VIEW)}
        title={evidence.name ?? ''}
        descriptions={evidence?.text ? [evidence?.text] : []}
        sx={{ mb: 2 }}
      />
    );
    case EvidenceFulfillment.ApprovalOnly: return (
      <SignEvidencePanel
        evidence={evidence}
        complete={complete}
        onSign={() => action(EvidenceAction.VIEW)}
        onView={() => action(EvidenceAction.DOWNLOAD)}
        title={evidence.name ?? ''}
        descriptions={evidence?.text ? [evidence?.text] : []}
        evidenceUploadProps={{
          evidence,
          claim,
        }}
        sx={{ mb: 2 }}
      />
    );
    case EvidenceFulfillment.Member:
    case EvidenceFulfillment.ThirdPartyViaMember:
      return (
        <EmailEvidencePanel
          evidence={evidence}
          complete={complete}
          title={evidence.name ?? ''}
          descriptions={evidence?.text ? [evidence?.text] : []}
          evidenceUploadProps={{
            evidence,
            claim,
          }}
          sx={{ mb: 2 }}
        />
      );
    case EvidenceFulfillment.ThirdParty:
      return (
        <ChaseEvidencePanel
          title={evidence.name ?? ''}
          complete={complete}
          descriptions={evidence?.text ? [evidence?.text] : []}
          evidenceUploadProps={{
            evidence,
            claim,
          }}
          sx={{ mb: 2 }}
        />
      );
    default: return null;
  }
}

interface EvidencePanelProps {
  evidence: ClaimEvidence,
  claim: MyClaim,
}

function EvidencePanel({
  evidence,
  claim,
} : EvidencePanelProps) {
  const navigate = useNavigate();
  const handleAction = useCallback(async (evidenceAction: EvidenceAction = EvidenceAction.VIEW) => {
    if (evidenceAction === EvidenceAction.VIEW && claim.claimId && evidence.id) {
      navigate(generatePath(ClaimEvidencePage.path, { claimId: claim.claimId, evidenceId: evidence.id }));
    }
    if (evidenceAction === EvidenceAction.DOWNLOAD) {
      try {
        const evidenceDocument = evidence?.documents?.at(0);
        if (!evidenceDocument) {
          throw new Error('No document found on evidence record');
        }
        const promise = documentApi.getDocument(evidenceDocument.id);
        await documentApi.openMemberDocument(promise, evidenceDocument.name);
      } catch {
        toast('Error retrieving document', {
          position: 'bottom-left',
          type: 'error',
        });
      }
    }
  }, [evidence]);

  return getEvidencePanel(evidence, claim, handleAction);
}

export default EvidencePanel;
