import {
  Container,
  styled,
} from '@mui/material';

const horizontalSpace = 10;

export const MainContent = styled(Container)(({ theme }) => ({
  width: '100%',
  flex: '1 1 auto',
  marginTop: 0,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(horizontalSpace),
  '& > *': {
    flex: '1 1 auto',
  },
}));

export default {};
