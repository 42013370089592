/* eslint-disable react/no-danger */
import React from 'react';
import {
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';
import { EvidencePanelProps } from '../../../../services/evidence-helpers';
import { EvidenceCard } from './evidence-panel.styles';
import EvidenceUpload, { EvidenceUploadProps } from '../evidence-upload/evidence-upload';
import EvidenceReviewed from './evidence-reviewed';

interface ChaseEvidencePanelProps extends EvidencePanelProps {
  evidenceUploadProps: EvidenceUploadProps
}

function ChaseEvidencePanel({
  title,
  descriptions,
  evidenceUploadProps,
  ...props
} : ChaseEvidencePanelProps) {
  return (
    <EvidenceCard {...props}>
      <CardHeader
        title={title}
        subheader={descriptions.map((description) => <div key={description} dangerouslySetInnerHTML={{ __html: description }} />)}
      />
      <CardContent>
        <Stack gap={2}>
          <EvidenceUpload {...evidenceUploadProps} />
          {props.complete && <EvidenceReviewed />}
        </Stack>
      </CardContent>
    </EvidenceCard>
  );
}

export default ChaseEvidencePanel;
