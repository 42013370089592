import * as yup from 'yup';

export const medicalPermissionsFormSchema = yup.object({
  reviewThirdPartyEvidence: yup.boolean(),
  name: yup.string().required(),
  signature: yup.string().required(),
  consentDate: yup.string().required(),
});

export default {};
