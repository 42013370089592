const MuiTable = {
  styleOverrides: {
    root: ({ ownerState }: any) => ({
      '& > thead > tr > th': {
        ...(ownerState.size === 'small' && {
          paddingBottom: 16,
          paddingTop: 16,
        }),
      },
    }),
  },
};

export default MuiTable;
