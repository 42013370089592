import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPanel from './components/error-panel';

function Error404() {
  const { t } = useTranslation();
  return (
    <ErrorPanel
      title={t('errors.404.title')}
    >
      {t('errors.404.description')}
    </ErrorPanel>
  );
}

export default Error404;
