import http from './http';
import { MyClaim } from './models/my-claim';
import { ClaimEvidences } from './models/claim-evidences';
import { ClaimEvidenceFulfilmentGroups } from './models/claim-evidence-fulfilment-groups';

async function getMemberClaims(): Promise<MyClaim[]> {
  return http.get('/api/v1/members/claims');
}

async function getMemberClaim(claimId: string): Promise<MyClaim> {
  return http.get(`/api/v1/members/claims/${claimId}`);
}

async function getClaimEvidences(claimId: string): Promise<ClaimEvidences> {
  return http.get(`/api/v1/members/claims/${claimId}/evidences`);
}

async function getClaimFulfilment(claimId: string): Promise<ClaimEvidenceFulfilmentGroups> {
  return http.get(`/api/v1/members/claims/${claimId}/fulfilments`);
}

const claimsApi = {
  getMemberClaims,
  getMemberClaim,
  getClaimEvidences,
  getClaimFulfilment,
};

export default claimsApi;
