import variables from '../variables';

const MuiSelect = {
  styleOverrides: {
    root: () => ({
      backgroundColor: variables.colors.white,
      '&.Mui-disabled': {
        backgroundColor: variables.colors.white,
      },
    }),
    select: () => ({
      backgroundColor: variables.colors.white,
      minHeight: 26,
      paddingTop: 8,
      paddingBottom: 8,
      '&.Mui-disabled': {
        backgroundColor: variables.colors.white,
      },
      '&.MuiInputBase-inputSizeSmall': {
        paddingTop: 5,
        paddingBottom: 5,
      },
    }),
  },
};

export default MuiSelect;
