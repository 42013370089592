import { Breakpoint, useTheme } from '@mui/material';
import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

function useBreakpoint(breakpoint: Breakpoint) {
  const { breakpoints } = useTheme();
  const [state, setState] = useState(window.innerWidth >= breakpoints.values[breakpoint]);

  useEffect(() => {
    const onResize = throttle(
      () => {
        setState(window.innerWidth >= breakpoints.values[breakpoint]);
      },
      100,
      { leading: true, trailing: true },
    );

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [breakpoints.values, breakpoint]);

  return state;
}

export default useBreakpoint;
