import { useCallback, useState } from 'react';
import SequentialTaskQueue from '../services/sequential-task-queue';

export default function useBusyState(queue?: SequentialTaskQueue) {
  const [busy, setBusy] = useState(false);
  const withBusyState = useCallback(<T extends (...args: any) => any>(callback?: T) => async (...args: Parameters<T>) => {
    if (!callback) {
      return;
    }
    setBusy(true);
    try {
      await callback(...args);
    } finally {
      setBusy(queue ? !queue.isEmpty : false);
    }
  }, [setBusy, queue]);
  return [busy, withBusyState] as const;
}
