import React, { useCallback } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Container } from './file-upload.styles';
import { mbToByte } from '../../utils/converters';
import useBreakpoint from '../../hooks/use-breakpoints';

const MAX_FILE_SIZE = 50;

interface EvidenceUploadProps {
  onFileSelect: (files: File[]) => void;
  loading?: boolean;
}

function FileUpload({
  onFileSelect,
  loading = false,
}: EvidenceUploadProps) {
  const { t } = useTranslation();
  const isWide = useBreakpoint('sm');

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    onFileSelect(acceptedFiles);
    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          const errorMsg = error && error.code === 'file-too-large' ? `File ${file.name} is too large. Maximum size is ${MAX_FILE_SIZE}MB.` : null;
          toast(errorMsg ?? error.message, {
            position: 'bottom-left',
            type: 'error',
          });
        });
      });
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: true,
    maxSize: mbToByte(MAX_FILE_SIZE),
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
      'application/msword': ['.doc', '.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-outlook': ['.msg'],
      'message/rfc822': ['.eml'],
    },
  });

  return (
    <Container {...getRootProps({
      isFocused,
      isDragAccept,
      isDragReject,
      role: 'none',
    })}
    >
      <input {...getInputProps()} disabled={loading} />
      <Stack direction={isWide ? 'row' : 'column'} gap={0.75} alignItems="center">
        {!loading ? (
          <>
            <Box sx={{ color: 'info.main', textDecoration: 'underline' }}>{t(('components.fileUpload.chooseFile'))}</Box>
            <div>{`${t('common.or')} ${t('components.fileUpload.dragHere')} (${t('components.fileUpload.maxFileSize')} ${MAX_FILE_SIZE}mb)`}</div>
          </>
        ) : (
          <Stack direction="row" gap={2}>
            <CircularProgress size={24} />
            <Typography variant="body1">
              {t('components.fileUpload.uploading')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

export default FileUpload;
