import { Components } from '@mui/material';
import variables from '../variables';
import typography from '../typography';

const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: () => ({
      ...typography.body2,
      lineHeight: 1.5,
      color: variables.colors.greyDark,

      '&.Mui-focused': {
        color: variables.colors.greyDark,
      },
      '&.Mui-error': {
        color: variables.colors.greyDark,
      },
    }),
  },
};

export default MuiFormLabel;
