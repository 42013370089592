import { styled } from '@mui/material/styles';
import MuiContainer from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const Container = styled(MuiContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  '& h1': {
    fontFamily: theme.typography.body1.fontFamily,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      marginTop: theme.spacing(8),
    },
  },
  '& > p': {
    fontFamily: theme.typography.h6.fontFamily,
  },
  '& > hr': {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export default Container;
