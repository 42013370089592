import React from 'react';
import {
  Button,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MyClaim } from '../../../../services/models/my-claim';
import { ClaimPanel as ClaimPanelStyled } from './claim-panel.style';
import { ClaimIncapacityType, ClaimListType } from '../../../../services/claims-helpers';
import { asClientDate } from '../../../../utils/converters';
import useBreakpoint from '../../../../hooks/use-breakpoints';
import DataDisplay from '../../../../components/data-display';

export interface ClaimsPanelProps {
  claim: MyClaim;
  type?: ClaimListType;
  onView: (claim: MyClaim) => unknown;
}

function ClaimPanel({
  claim,
  type = 'Active',
  onView,
}: ClaimsPanelProps) {
  const { t } = useTranslation();
  const isWide = useBreakpoint('md');
  const incapacity = claim.incapacity?.toLowerCase();
  const isValidIncapacity = incapacity === ClaimIncapacityType.Illness || incapacity === ClaimIncapacityType.Injury;

  return (
    <ClaimPanelStyled>
      <Stack direction={isWide ? 'row' : 'column'} justifyContent="space-between">
        <DataDisplay
          label={`${t('common.claim.prefix')}${claim.claimId}`}
          value={isValidIncapacity ? t(`common.claim.incapacity.${incapacity}`) : t('common.claim.incapacity.not_applicable')}
          labelProps={{ variant: 'caption' }}
          valueProps={{ variant: 'h2' }}
          orientation={isWide ? 'vertical' : 'horizontal'}
        />
        <DataDisplay
          label={t('components.claims.claimPanel.claimDate')}
          value={asClientDate(claim.firstNotificationOfLoss)}
          labelProps={{ variant: 'body1' }}
          orientation={isWide ? 'vertical' : 'horizontal'}
        />
        <DataDisplay
          label={t('components.claims.claimPanel.policyType')}
          value={claim.policyName ?? ''}
          labelProps={{ variant: 'body1' }}
          orientation={isWide ? 'vertical' : 'horizontal'}
        />
        <DataDisplay
          label={t('components.claims.claimPanel.policyStatus')}
          value={t(`common.claim.claimStatus.${claim.assessmentStatus?.toLowerCase() ?? 'unknown'}`)}
          labelProps={{ variant: 'body1' }}
          orientation={isWide ? 'vertical' : 'horizontal'}
          sx={{ flex: 1 }}
        />
        <Button
          variant={type === 'Active' ? 'contained' : 'outlined'}
          color={type === 'Active' ? 'primary' : 'secondary'}
          onClick={() => onView(claim)}
        >
          {t('common.view')}
        </Button>
      </Stack>
    </ClaimPanelStyled>
  );
}

export default ClaimPanel;
