import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
// eslint-disable-next-line import/no-cycle
import { memberSlice } from '../features/MemberSlice';
// eslint-disable-next-line import/no-cycle
import { claimDetailSlice } from '../features/ClaimDetailSlice';

const reducer = combineReducers({
  member: memberSlice.reducer,
  claimDetail: claimDetailSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV !== 'development') {
      return getDefaultMiddleware();
    }
    return getDefaultMiddleware().concat(logger);
  },
  preloadedState,
});

export const store = setupStore();

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
