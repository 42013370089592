import { Box, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#fafafa',
  borderTop: `1px solid ${alpha(theme.palette.common.surface5, 0.15)}`,
  borderBottom: `1px solid ${alpha(theme.palette.common.surface5, 0.15)}`,
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.05)',
  zIndex: theme.zIndex.drawer - 10,
  display: 'flex',
  flexDirection: 'row',
  minHeight: 65,
  minWidth: 130,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '& > button:first-of-type': {
    textDecoration: 'underline',
    fontFamily: theme.typography.body2.fontFamily,
  },
}));

export default {};
