import React, { useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Container } from './signature-upload.styles';

interface EvidenceUploadProps {
  onFileSelect: (files: File[]) => void;
}

function SignatureUpload({
  onFileSelect,
}: EvidenceUploadProps) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileSelect(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
    },
  });

  return (
    <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <Stack direction="column" gap={0.5} alignItems="center">
        <Box sx={{ color: 'info.main', textDecoration: 'underline' }}>{t(('components.signatureUpload.chooseFile'))}</Box>
        <div>{t(('common.or'))}</div>
        <div>{t(('components.signatureUpload.dragHere'))}</div>
      </Stack>
    </Container>
  );
}

export default SignatureUpload;
