import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { Option, getLabelAndValue } from '../types';

export interface SelectProps extends MuiSelectProps {
  name: string;
  options: (string | Option)[];
  labelTranslationBasePath?: string;
  disabledOptions?: string[];
}

function Select({
  id,
  name,
  labelTranslationBasePath = undefined,
  value,
  onChange,
  options,
  placeholder,
  disabledOptions = [],
  ...otherProps
}: SelectProps) {
  const { t } = useTranslation();

  return (
    <MuiSelect
      id={id}
      name={name}
      size="small"
      value={value}
      onChange={onChange}
      sx={{ minWidth: 150 }}
      {...otherProps}
    >
      <MenuItem disabled={value === ''} value="">{placeholder ?? t('common.select')}</MenuItem>
      {options.map((option) => {
        const { label, value: optionValue } = getLabelAndValue(option, t, labelTranslationBasePath);
        return (
          <MenuItem
            key={`${name}_${optionValue}`}
            value={optionValue}
            disabled={disabledOptions.includes(optionValue)}
          >
            {label}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
}

export default Select;
