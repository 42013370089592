import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.background4,
  '& > div': {
    border: 'none',
    minHeight: 150,
    '&:has(input:focus)': {
      border: 'none',
    },
    '& input': {
      fontSize: 40,
      fontFamily: 'bradley hand',
      textAlign: 'center',
    },
  },
}));

export default StyledTextField;
